import { CommonDialogs } from "@mcleod/common";
import { Button, ButtonProps, DataDisplayEvent, DataSourceExecutionEvent, Dialog, Image, ImageType, Label, LabelProps, Layout, MultiButton, Panel, TableRowDisplayEvent, Toast } from "@mcleod/components";
import { SaveButtonPropDefinitions } from "@mcleod/components/src/components/savebutton/SaveButtonProps";
import { Api, DisplayType, FileUtil, HorizontalAlignment, ModelRow, StringUtil, getLogger, getUnauthSettings } from "@mcleod/core";
import { createTableEmptyComponent } from "./CarrierPortal";
import { AutogenLayoutTableCarrierAvailable } from "./autogen/AutogenLayoutTableCarrierAvailable";
import { CarrierOfferAcceptPdfDialog } from "./offer/CarrierOfferAcceptPdfDialog";

const log = getLogger("portal-carrier/TableCarrierAvailable");

const basicDropdownProps: Partial<ButtonProps> = {
    borderWidth: 0,
    borderRadius: 0,
    marginLeft: 0,
    paddingLeft: 8,
    width: "100%",
    align: HorizontalAlignment.CENTER,
    fontSize: "medium"
};

export class TableCarrierAvailable extends AutogenLayoutTableCarrierAvailable {

    override onLoad() {
        this.mainDataSource.addBeforeExecutionListener((event: DataSourceExecutionEvent) => event.filter.type = "pending");
        this.tableAvailable.emptyComponent = createTableEmptyComponent();
    }

    tableAvailableOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();

        const offerPanel = tableRow?.findComponentById("panelOffer") as Panel;
        offerPanel.visible = true
        const offerPanelButton = tableRow?.findComponentById("panelOfferButton") as Panel;
        offerPanelButton.add(this.createButtonOrLabel(tableRow.data));
        const counterLabel = tableRow?.findComponentById("labelCounterOfferSent") as Label;
        const offerPlacedLabel = tableRow?.findComponentById("labelOfferPlaced") as Label;
        const offerDateLabel = tableRow?.findComponentById("labelOfferDate") as Label;
        const countered = tableRow.data.get("offer_status") == "O";
        const carrierCountered = tableRow.data.getBoolean("is_carrier_counter");
        counterLabel.visible = carrierCountered;
        offerPlacedLabel.visible = !carrierCountered;
        offerDateLabel.visible = !carrierCountered;

        const labelEmail = tableRow?.findComponentById("labelAvailableEmail") as Label;
        if (labelEmail && labelEmail.visible) {
            const email = tableRow.data.get("contact_email");
            if (email == null) return;
            labelEmail.link = "mailto:" + email + "?subject=Question about Available Order "
                + tableRow.data.get("order_id") + " " + tableRow.data.get("origin_loc") + " to " + tableRow.data.get("destination_loc");
        }
    }


    private createButtonOrLabel(modelRow: ModelRow): MultiButton | Label {
        if (modelRow.getBoolean("is_carrier_counter"))
            return new Label({ caption: modelRow.get("rate_currency"), width: 100, fontBold: true });

        const offerResponseButton = new MultiButton({
            themeKey: "editRowDecorator.multiButton",
            rowBreak: false,
            height: 140,
            color: SaveButtonPropDefinitions.getDefinitions().color.defaultValue,
            backgroundColor: SaveButtonPropDefinitions.getDefinitions().backgroundColor.defaultValue
        });

        offerResponseButton.primaryButton.setProps({
            themeKey: "editRowDecorator.primaryButton",
            id: "ratePrimaryBtn",
            caption: modelRow.get("rate_currency"),
            onClick: () => { },
        });
        offerResponseButton.dropdownItems = this.getOfferActions(offerResponseButton, modelRow);
        return offerResponseButton;

    }

    getOfferActions(button: MultiButton, modelRow: ModelRow): Partial<LabelProps>[] {
        const acceptOffer = { caption: "Accept Offer", onClick: () => this.acceptOffer(button, modelRow), ...basicDropdownProps };
        const counterOffer = { caption: "Counter Offer", onClick: () => this.counterOffer(button, modelRow), ...basicDropdownProps };
        const declineOffer = { caption: "Decline Offer", onClick: () => this.declineOffer(button, modelRow), ...basicDropdownProps };

        if (modelRow.get("rate") > 0) {
            if (modelRow.get("allow_cntr_offer") == 'Y')
                return [acceptOffer, counterOffer, declineOffer];
            return [acceptOffer, declineOffer];
        }
        button.primaryButton.setProps({ caption: "Make Offer", onClick: () => this.counterOffer(button, modelRow, true) })
        return [declineOffer];
    }

    counterOffer(offerResponseButton: MultiButton, modelRow: ModelRow, makeOffer: boolean = false): void {
        const caption = "Enter the amount you wish " + (makeOffer ? "offer for the load" : "to counter by");
        const title = makeOffer ? "Make Offer" : "Counter Offer";
        CommonDialogs.showInputDialog(caption, title, { displayType: DisplayType.CURRENCY }).then(
            value => {
                if (value != undefined) {
                    offerResponseButton.busy = true;
                    Api.post("portal/carrier/carrier-counter-offer", { offer_id: modelRow?.get("offer_id"), counter_amount: value, is_broker: false }).then(response => {
                        offerResponseButton.busy = false;
                        if (!StringUtil.isEmptyString(response?.data[0]?.success)) {
                            Toast.showSuccessToast(response.data[0].success);
                            this.mainDataSource.search({});
                        }
                    }).catch(err => {
                        offerResponseButton.busy = false;
                        CommonDialogs.showError(err);
                    });
                }
            }
        )
    }

    declineOffer(offerResponseButton: MultiButton, modelRow: ModelRow): void {
        CommonDialogs.showInputDialog("Select a reason for declining this offer.", "Decline Offer",
            {
                items: [{ caption: "Not My Lane", value: "L" },
                { caption: "No Equipment", value: "E" }]
            }).then(
                value => {
                    if (value != undefined) {
                        offerResponseButton.busy = true;
                        const declineReason = value == "Not My Lane" ? "L" : "E";
                        Api.post("portal/carrier/carrier-decline-offer", { offer_id: modelRow?.get("offer_id"), decline_reason: declineReason }).then(response => {
                            offerResponseButton.busy = false;
                            if (!StringUtil.isEmptyString(response.data[0].success)) {
                                Toast.showSuccessToast(response.data[0].success);
                                this.mainDataSource.search({});
                            }
                        }).catch(err => {
                            offerResponseButton.busy = false;
                            CommonDialogs.showError(err);
                        });
                    }
                }
            )
    }

    async acceptOffer(offerResponseButton: MultiButton, modelRow: ModelRow): Promise<void> {
        const companySettings = getUnauthSettings().company_settings;
        const legalTerm = companySettings["require_legal_term"];
        const orderConf = companySettings["require_order_term"];
        await this.shouldShowOfferDialog(modelRow?.get("offer_id")).then(response => {
            //you want is_erate result to be false. Otherwise don't show dialog
            if (response == false && (legalTerm === "Y" || orderConf === "Y")) {
                this.getPdfData(modelRow, legalTerm, orderConf);
            }
            else
                this.acceptOfferConfirmationDialog(offerResponseButton, modelRow);
        })
    }

    acceptOfferConfirmationDialog(acceptButton: Button | MultiButton, modelRow: ModelRow, dialog?: Dialog) {
        CommonDialogs.showYesNo("Are you sure you want to accept the offer?",
            "Order " + modelRow?.get("order_id") + " - Accept Offer Confirmation").then(clickedYes => {
                if (clickedYes) {
                    acceptButton.busy = true;
                    Api.post("portal/carrier/carrier-accept-offer", { offer_id: modelRow?.get("offer_id") }).then(response => {
                        acceptButton.busy = false;
                        if (!StringUtil.isEmptyString(response.data[0].success)) {
                            this.mainDataSource.search({});
                            if (dialog != null)
                                dialog.close();
                            Toast.showSuccessToast(response.data[0].success);
                        }
                    }).catch(err => {
                        acceptButton.busy = false;
                        CommonDialogs.showError(err);
                    });
                }
            });
    }

    shouldShowOfferDialog(offerId: String): Promise<Boolean> {
        return new Promise((resolve) => {
            Api.post("portal/carrier/erate_confirmation", { offer_id: offerId }).then(response => {
                resolve(response.data[0]?.is_erate);
            });
        })
    }

    async getPdfData(modelRow: ModelRow, legalTerm: String, orderConf: String) {
        const orderId = modelRow?.get("order_id");
        const movementId = modelRow?.get("movement_id");

        let imgTerms;
        let imgConf;
        const responses = await Promise.all([legalTerm === "Y" && Api.post("portal/carrier/legal_terms"), orderConf === "Y"
            && Api.post("portal/carrier/order_confirmation", { order_id: orderId, movement_id: movementId })]);
        if (responses != null && responses.length > 0) {
            const layout = Layout.getLayout("portal-carrier/offer/CarrierOfferAcceptPdfDialog") as CarrierOfferAcceptPdfDialog;
            const dialog = CommonDialogs.createDialog(layout, { fillHeight: true, minWidth: 800, title: "Offer Details", okVisible: false });
            dialog.show();
            layout.addLayoutLoadListener(() => {
                const acceptButton = (layout.buttonAccept as Button);
                (layout.buttonCancel as Button).addClickListener(() => { dialog.close(); });
                acceptButton.addClickListener(() => { this.acceptOfferConfirmationDialog(acceptButton, modelRow, dialog) });

                if (responses[0] !== false) {
                    imgTerms = new Image({ imageType: ImageType.PDF, fillHeight: true, minWidth: 700, id: "imageLegalTerms" });
                    imgTerms.imageBytes = responses[0].data[0].legal_terms;
                    (layout.panelPdf as Panel).add(imgTerms);
                }
                if (responses[1] !== false) {
                    imgConf = new Image({ imageType: ImageType.PDF, fillHeight: true, minWidth: 700, id: "imageOrderConf" });
                    imgConf.imageBytes = responses[1].data[0].rate_confirmation;
                    (layout.panelPdf as Panel).add(imgConf);
                }
                if (imgTerms != null && !StringUtil.isEmptyString(imgTerms.imageBytes))
                    (layout.buttonDownloadTerms as Button).addClickListener(() => {
                        FileUtil.downloadBase64AsFile(responses[0].data[0].legal_terms, `LegalTerms ${orderId}.pdf`);
                    })
                else
                    (layout.buttonDownloadTerms as Button).visible = false;
                (layout.buttonDownloadRateConfirmation as Button).visible = false;
            });
        }
    }

    labelDistanceOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        if (label.getDataValue() != null)
            label.caption += " MI";
    }
}
