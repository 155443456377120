import { Button, ClickEvent, Label, TableColumn, TableRow, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { FtlQuote } from "./FtlQuote";
import { LtlQuote } from "./ltl/LtlQuote";
import { AutogenLayoutTableCustomerQuotes } from "./ltl/autogen/AutogenLayoutTableCustomerQuotes";

export class TableCustomerQuotes extends AutogenLayoutTableCustomerQuotes {

    private allColumns: TableColumn[];

    override onLoad() {
        this.allColumns = [...this.tableQuotes.columns];
        this.mainDataSource.orderBy = [{ field: "shipper_stop.sched_arrive_early", sort: "desc" }];
    }

    buttonOpenQuoteOnClick(event: ClickEvent) {
        const button = event.target as Button;
        button.busy = true;
        const tableRow = TableRow.getContainingTableRow(event.target as Button);
        const rowOrderMode: string = tableRow.data.get("order_mode");
        const rowIsBrLtl: boolean = tableRow.data.get("is_br_ltl");
        if (rowOrderMode == "L" || rowIsBrLtl)
            LtlQuote.slideInCrudDecorator(tableRow.data.get("id"))
                .finally(() => button.busy = false);
        else {
            FtlQuote.slideInCrudDecorator(tableRow.data.get("id"))
                .finally(() => button.busy = false);
        }
    }

    // When exporting to excel the column header titles couldn't be resolved when using the
    // CityState component. Using a label that is not bound to a ModelRow to display the city/state/zip
    // to work around this issue.
    tableQuotesOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const rowOrderMode: string = tableRow.data.get("order_mode");
        const freightOrDistance = tableRow.findComponentById("textboxFreightOrDistance") as Textbox;
        if (rowOrderMode == "L") {
            freightOrDistance.caption = "Freight Class";
        } else {
            freightOrDistance.caption = "Distance";
        }
        const shipperLbl = tableRow.findComponentById("labelShipperCityState") as Label;
        shipperLbl.caption = tableRow.data.get("shipper_city_state_zip");
        const consigneeLbl = tableRow.findComponentById("labelConsigneeCityState") as Label;
        consigneeLbl.caption = tableRow.data.get("consignee_city_state_zip");
    }
}

