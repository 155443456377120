import { AutogenLayoutCarrierCallins } from "./autogen/AutogenLayoutCarrierCallins";

export class CarrierCallins extends AutogenLayoutCarrierCallins {

    override onLoad() {
        this.addLayoutLoadListener(event => {
            this.citystate1.textCombined.onSelectItem = () => {
                this.citystate1.textCombined.tooltipCallback = null;
                return undefined;
            }
        });
    }
}


