import { Checkbox, DataSource, HorizontalSpacer, Label, Layout, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutDriverChoicePreferences extends Layout {
    checkboxAllowTrailerDrop: Checkbox;
    checkboxExcludeCommodity: Checkbox;
    checkboxExcludeEquipType: Checkbox;
    horizontalspacer1: HorizontalSpacer;
    labelPriorityOneLG: Label;
    labelPriorityOneMD: Label;
    labelPriorityTwoLG: Label;
    labelPriorityTwoMD: Label;
    sourceDriverChoicePreferences: DataSource;
    textboxAddress: Textbox;
    textboxChoiceCommodity: Textbox;
    textboxChoiceEquipType: Textbox;
    textboxChoicePriority1: Textbox;
    textboxChoicePriority1Value: Textbox;
    textboxChoicePriority2: Textbox;
    textboxChoicePriority2Value: Textbox;
    textboxName: Textbox;
    textboxPtaDate: Textbox;
    textboxTractorId: Textbox;
}
