import { ClickEvent, DataSource, DataSourceExecutionEvent, DataSourceMode } from "@mcleod/components";
import { ModelRow, getAuthSettings } from "@mcleod/core";
import { createTableEmptyComponent } from "../CustomerPortal";
import { ShareOrder } from "../ShareOrder";
import { LtlUtil } from "./LtlUtil";
import { AutogenLayoutAdditionalDetailsLTL } from "./autogen/AutogenLayoutAdditionalDetailsLTL";

export class AdditionalDetailsLTL extends AutogenLayoutAdditionalDetailsLTL {
    private orderId: string;

    /** This is an event handler for the onClick event of buttonShare.  */
    buttonShareOnClick(event: ClickEvent) {
        ShareOrder.onClick(event, this.orderId);
    }

    /** This is an event handler for the afterExecution event of sourceCustomerOrderDetail.  */
    sourceCustomerOrderDetailAfterExecution(event: DataSourceExecutionEvent) {


        const userSettings = getAuthSettings().user_settings;
        if (userSettings?.user_type == "L" && event.dataSource.activeRow?.get("is_asset_ltl"))
            this.panelBilling.visible = true;

        if (event.dataSource.activeRow?.get("is_br_ltl"))
            this.panelCarrier.visible = true;

        this.tableReferenceNumbers.emptyComponent = createTableEmptyComponent("No reference numbers available at this time");

        const email = event.dataSource.activeRow?.get("email_address");
        this.orderId = event.dataSource.activeRow?.get("orders.id");
        if (email != null) {
            const subject = `Question about Order ${this.orderId}`;
            this.labelOrderEmail.link = `mailto:${email}?subject=${subject}`;
        }
        const row = event.dataSource.activeRow as ModelRow;
        this.populateDataSource(row.get("reference_numbers"), this.sourceOrderReferenceNumbers);
    }

    private populateDataSource(data: [], dataSource: DataSource) {
        dataSource.setRowsAndMode(DataSourceMode.NONE, data?.map(data => new ModelRow(dataSource.url, false, data)) ?? []);
    }

    buttonBOLOnClick(event: ClickEvent) {
        LtlUtil.downloadBOL(this.orderId);
    }
}
