import { DataDisplayEvent } from "@mcleod/components";
import { AutogenLayoutPopupTableCustomerOrders } from "./autogen/AutogenLayoutPopupTableCustomerOrders";

export class PopupTableCustomerOrders extends AutogenLayoutPopupTableCustomerOrders {

    override onLoad() {
        this.layoutTableCustomerOrder.replaceMainDatasource(this.sourceOrders);
        this.layoutTableCustomerOrder.tableOrders.allowExport = false;
        this.layoutTableCustomerOrder.tableOrders.headerVisible = false;
        this.layoutTableCustomerOrder.configureTable();
    }

    sourceOrdersOnDisplay(event: DataDisplayEvent) {
        if (this.sourceOrders.data?.length == 1)
            this.labelDetailForOrder.caption = "Detail for order " + this.activeRow?.get("orders.id");
        else if (this.sourceOrders.data?.length > 1)
            this.labelDetailForOrder.caption = "Orders in " + this.activeRow?.get("shipper");
    }
}
