import { DropdownItem, Textbox } from "@mcleod/components";
import { DatePart, DateUtil, getUnauthSettings } from "@mcleod/core";

const dateFormatValue = 'yyyy-MM-dd HH:mm';
const dateFormatDropDown = 'MM/dd';

export function setSelectedDay(textbox: Textbox, date: any) {
    if (date == null) return;
    const items = textbox.items as DropdownItem[];
    const parsedDate = DateUtil.parseDate(date);
    if (parsedDate != null) {
        const selectedDate = DateUtil.formatDate(parsedDate, dateFormatDropDown);
        const selectedDateCaption = `(${selectedDate})`;
        if (items != null) {
            items.forEach(item => {
                if (item.caption.includes(selectedDateCaption)) {
                    textbox.selectedItem = item;
                    return;
                }
            })
        }
    }
    // if the date is outside the maxDaysOut range, add a DropdownItem.
    if (textbox.selectedItem == null) {
        items.push({ caption: DateUtil.formatDate(parsedDate, 'EEEE (' + dateFormatDropDown + ')'), value: date });
        textbox.selectedItem = items[items.length - 1];
    }
}

export function getDatesBasedOnMaxDaysOut(): DropdownItem[] {
    const settings = getUnauthSettings().company_settings;
    const includeSunday = settings["ltl_include_sunday"];
    const includeSaturday = settings["ltl_include_saturday"];
    const includeHolidays = settings["ltl_include_holiday"];
    const maxDaysOut = settings["ltl_max_days_out"] ?? 6;

    const dateItems = [];
    let todayStr = "";

    if (maxDaysOut && maxDaysOut >= 0) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        todayStr = DateUtil.formatDateTime(today, dateFormatValue);
        if (includeDay(today, includeSunday, includeSaturday, includeHolidays)) {
            dateItems.push({ caption: "Today (" + DateUtil.formatDate(today, dateFormatDropDown) + ")", value: todayStr });
        }
        for (let i = 1; i <= maxDaysOut; i++) {
            const nextDay = DateUtil.dateAdd(DatePart.DAY, today, i);
            if (!includeDay(nextDay, includeSunday, includeSaturday, includeHolidays)) {
                continue;
            }
            const nextDayStr = DateUtil.formatDateTime(nextDay, dateFormatValue);
            if (i === 1)
                dateItems.push({ caption: "Tomorrow (" + DateUtil.formatDate(nextDay, dateFormatDropDown) + ")", value: nextDayStr });
            else
                dateItems.push({ caption: DateUtil.formatDate(nextDay, 'EEEE (' + dateFormatDropDown + ')'), value: nextDayStr });
        }
    }
    return dateItems;
}


function includeDay(date: Date, includeSunday: boolean, includeSaturday: boolean, includeHolidays: boolean) {
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 0 && !includeSunday) {
        return false;
    }
    else if (dayOfWeek === 6 && !includeSaturday) {
        return false;
    }
    else if (DateUtil.isHoliday(date) && !includeHolidays) {
        return false;
    }
    return true;
}

