import { CommonDialogs } from "@mcleod/common";
import { ClickEvent, DataSourceMode, DropdownItem, Label, Snackbar, TableAddRowResult, TableRow, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { Block, DateUtil, DisplayType, Navigation, StringUtil, getUnauthSettings } from "@mcleod/core";
import { AddStop } from "./AddStop";
import { showCreditSnackbar, userCanEnterFtlOrder, validateBeforeAddOrder, validateLocationsPresent } from "./OrderValidation";
import { AutogenLayoutFtlOrder } from "./autogen/AutogenLayoutFtlOrder";

export class FtlOrder extends AutogenLayoutFtlOrder {
    private errorHandler = (error: any) => {
        CommonDialogs.showError(error);
        // defect exists where posting fails but linkedModels remain,
        // causing duplicates to be sent to server
        this.activeRow["linkedModels"] = null;
    }
    private _shipper: AddStop;
    public get shipper(): AddStop {
        return this._shipper;
    }

    override async onLoad(): Promise<void> {
        if (userCanEnterFtlOrder()) {
            this.sourceStop.preventFocusFirstField = true;
            this.sourceOrder.preventFocusFirstField = true;
            this.labelWelcome.caption = getUnauthSettings().company_settings["order_welcome_message"];
            this.mainDataSource.setRowsAndMode(DataSourceMode.ADD, [await this.mainDataSource.createBlankRow()]);
            await this.addStops();
            this.buttonCancel.addClickListener(() => Navigation.navigateTo("/"));
            validateLocationsPresent();
            this.addLayoutLoadListener(() => {
                validateBeforeAddOrder(this);
            })
        } else {
            Navigation.navigateTo("/");
        }
        return Promise.resolve();
    }


    buttonAddStopOnClick(event: ClickEvent) {
        this.addStop().then(row => {
            row?.data?.set("stop_type", "SO");
            row.scrollIntoView({ block: Block.CENTER });
        });
    }

    async addStops() {
        this.tableStops.data = [];
        await this.addStop().then(row => row?.data?.set("stop_type", "PU"));
        await this.addStop().then(row => row?.data?.set("stop_type", "SO"))
    }

    async addStop(): Promise<TableRow> {
        return await this.tableStops.dataSource.createBlankRow().then(row => {
            const addRowResult: TableAddRowResult = this.tableStops.addRow(row, { mode: TableRowMode.ADD }, { display: true, addToData: true, save: false });
            return addRowResult.row;
        });
    }

    /** This is an event handler for the onRowDisplay event of tableStops.  */
    tableStopsOnRowDisplay(event: TableRowDisplayEvent) {
        const row = event.getTableRow();
        const stopData = row.data;
        (row.findComponentById("labelSequence") as Label).caption = (row.index + 1).toString();
        const stopLayout = row.findComponentById((comp) => comp instanceof AddStop) as AddStop;
        stopLayout.addLayoutLoadListener(() => {
            stopLayout.textPickupDate.displayType = DisplayType.DATE;
            stopLayout.isFtlOrder = true;
            stopLayout.initialize(stopData, row.index == 0);
            if (row.index == 0) {
                stopLayout.buttonDelete.visible = false;
                this._shipper = stopLayout;
            } else {
                stopLayout.buttonDelete.visible = true;
                stopLayout.buttonDelete.addClickListener(() => row.table.deleteRow(row.index));
            }
            this.setSelectedItem(stopLayout.textboxEarlyPickup);
            this.setSelectedItem(stopLayout.textboxLatePickup);
        });
    }

    setSelectedItem(textbox: Textbox) {
        const items = textbox.items as DropdownItem[];
        if (!StringUtil.isEmptyString(textbox.getDataValue()) && items != null) {
            items.forEach(item => {
                if (item.value == textbox.getDataValue()) {
                    textbox.selectedItem = item;
                    return;
                }
            })
        }
    }


    validateStops(): boolean {
        if (this.validateStopsTypes() == false)
            return false;
        if (this.validateStopEarlyLateTimes() == false)
            return false;
        return this.validateStopsInOrder();
    }

    validateStopEarlyLateTimes() {
        for (const row of this.sourceStop.data) {
            if (!row.isNull("sched_arrive_late")) {
                const lateDateTime = DateUtil.parseDateTime(row.get("sched_arrive_late"));
                const earlyDateTime = DateUtil.parseDateTime(row.get("sched_arrive_early"));
                if (earlyDateTime > lateDateTime) {
                    Snackbar.showWarningSnackbar({ caption: "Scheduled stop times must be in order i.e. earliest before latest. Please update.", padding: 0, width: 450 }, { id: "stopEarlyLateTimes", persist: false });
                    return false;
                }
            }
        }
        return true;
    }

    validateStopsInOrder(): boolean {
        let prevDateTime = null;
        for (const row of this.sourceStop.data) {
            if (!row.isNull("sched_arrive_early")) {

                const earlyDateTime = DateUtil.parseDateTime(row.get("sched_arrive_early"));
                if (prevDateTime !== null && prevDateTime > earlyDateTime) {
                    Snackbar.showWarningSnackbar("Scheduled stop date/times must be in order. Please update.", { id: "stopDates", persist: false });
                    return false;
                }
                prevDateTime = earlyDateTime;
            }
        }
        return true;
    }

    validateStopsTypes(): boolean {
        if (this.sourceStop.data.length < 2) {
            Snackbar.showWarningSnackbar("Please specify at least one pickup and one delivery", { id: "stopTypes", persist: false });
            return false;
        }
        const lastStop = this.sourceStop.data[this.sourceStop.data.length - 1];
        if (lastStop.get("stop_type") != "SO") {
            Snackbar.showWarningSnackbar({ caption: "The last stop must be a delivery", padding: 0, width: 450 }, { id: "stopTypes", persist: false });
            return false;
        }
        return true;
    }

    buttonSubmitOrderOnClick(event: ClickEvent) {
        this.validateBeforePost().then(valid => {
            if (valid == true) {
                this.mainDataSource.post(this.errorHandler).then(row => {
                    const orderId = row.get("id");
                    if (orderId != null)
                        Navigation.navigateTo("portal-customer/FtlOrderConfirmation?key=" + orderId);
                });
            }
        })
    }

    async validateBeforePost(): Promise<boolean> {
        let result = true;
        await this.shipper.validatePickupDates();

        const validation: (() => boolean)[] = [
            () => this.layoutOrderTerms.userAgreed(),
            () => this.shipper.hasValidPickupTimes,
            () => this.validateStops(),
            () => showCreditSnackbar(this.activeRow?.getBoolean("credit_status_valid", false), false)
        ]
        for (const isValid of validation)
            result = isValid() && result;

        if (result == true && !this.validateSimple()) {
            CommonDialogs.showError("Unable to save this record because data is missing or invalid.");
            return false;
        }
        return result;
    }
}
