import { Label, Panel } from "@mcleod/components";
import { HorizontalAlignment } from "@mcleod/core";
import { AutogenLayoutOrderImages } from "./autogen/AutogenLayoutOrderImages";

export class OrderImages extends AutogenLayoutOrderImages {

    private _orderId: String;

    override onLoad() {
        const emptyPanel = new Panel({ fillRow: true, align: HorizontalAlignment.CENTER, marginTop: 20 });
        emptyPanel.add(new Label({ caption: "No images available at this time.", fontBold: true }));
        this.tableImages.emptyComponent = emptyPanel;
    }

    set orderId(orderID: String) {
        this._orderId = orderID;
    }

    get orderId(): String {
        return this._orderId;
    }
}
