import { DataSource, DataSourceExecutionEvent, DataSourceMode, Panel, SelectionEvent, TableRowDisplayEvent } from "@mcleod/components";
import { HorizontalAlignment, ModelRow, getAuthSettings, getMapSettings, getUnauthSettings } from "@mcleod/core";
import { createTableEmptyComponent } from "./CustomerPortal";
import { AutogenLayoutOrderDetails } from "./autogen/AutogenLayoutOrderDetails";

export class OrderDetails extends AutogenLayoutOrderDetails {

    private orderId: string;

    public override onLoad() {
        this.layoutFTLAddlDetails.visible = false;
        this.layoutLTLAddlDetails.visible = false;
        this.tableLTLDetails.emptyComponent = createTableEmptyComponent("This order has no rating detail");
        this.panelRates.visible = getAuthSettings()?.user_settings?.user_type == "C";
        const showEta = this.shouldShowEta();
        this.panelEta.visible = showEta;
        this.panelMapEta.visible = showEta;
        const distanceCalcVendor = getMapSettings()?.distance_calc_vendor;
        if (distanceCalcVendor == "N") {
            this.tabMapView.visible = false;
        }

    }

    /** This is an event handler for the afterExecution event of sourceCustomerOrderDetail.  */
    sourceCustomerOrderDetailAfterExecution(event: DataSourceExecutionEvent) {
        this.orderId = event.dataSource.activeRow?.get("orders.id");
        const isLtl = event.dataSource.activeRow?.get("is_ltl");
        if (isLtl == false) {
            this.layoutFTLAddlDetails.visible = true;
            this.tabLTLDetails.visible = false;
            this.layoutLTLAddlDetails.visible = false;
        }
        else {
            this.layoutLTLAddlDetails.visible = true;
            this.tableFreightGroupItems.columns[0].headingCell.align = HorizontalAlignment.LEFT;
            this.sourceLtlOrderDetails.search({ search: this.orderId }).then(response => {
                const row = response.modelRows[0];
                this.populateDataSource(row.get("freight_items"), this.sourceFreightGroupItem);
                this.populateDataSource(row.get("charges"), this.sourceLtlRatings);
            });
        }
    }

    shouldShowEta(): boolean {
        let displayEta = getUnauthSettings()?.company_settings?.["display_eta"] ?? "A";
        if (getAuthSettings().user_settings?.user_type === "C")
            displayEta = getAuthSettings()["customer_settings"]?.display_eta;
        return "N" != displayEta;
    }

    private populateDataSource(data: [], dataSource: DataSource) {
        dataSource.setRowsAndMode(DataSourceMode.NONE, data?.map(data => new ModelRow(dataSource.url, false, data)) ?? []);
    }

    // Adds panelAddlDetails to the selected tab.
    // each tab contains a panel where id = [tab.id]+AddlDetails  ex: paneltabStopsAddlDetails, paneltabMapViewAddlDetails ...
    tabsetBeforeTabSelection(event: SelectionEvent) {
        const tabId = event.newSelection?.id
        const panel = this["panel" + tabId + "AddlDetails"] as Panel;
        if (panel != null) {
            panel.add(this.panelAddlDetails);
        }
    }

    /** This is an event handler for the onRowDisplay event of tableFreightGroupItems.  */
    tableFreightGroupItemsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const labelSKU = tableRow.findComponentById("labelSKU");
        labelSKU.tooltip = tableRow.data.get("product_sku");
    }
}
