import { ChangeEvent, DataSource, DropdownItem, Map, MapPin, Panel, Switch, Textbox } from "@mcleod/components";
import { MapVendor } from "@mcleod/components/src/components/map/MapProps";
import { ModelRow } from "@mcleod/core";
import { OrderStatus } from "./CustomerPortal";
import { TableCustomerOrders } from "./TableCustomerOrders";

interface dayCaption {
    orderStatus: OrderStatus,
    origin: string,
    destination: string;
}

export class TableCustomerOrdersMap extends Panel {
    private dayCaptions: dayCaption[] = [
        { orderStatus: OrderStatus.AVAILABLE, origin: "Scheduled pickup window is", destination: "Scheduled delivery window is" },
        { orderStatus: OrderStatus.PROGRESS, origin: "Actual pickup was", destination: "Scheduled delivery window is" },
        { orderStatus: OrderStatus.DELIVERED, origin: "Actual pickup was", destination: "Actual delivery was" }
    ];

    private tableCustomerOrders: TableCustomerOrders;
    private panelOriginDest = new Panel({ height: 70, fillRow: true });
    private switchOriginDest = new Switch({
        id: "switchOriginDest", width: 203, marginTop: 15, rowBreak: false,
        captionVisible: false, leftCaption: "Origin", rightCaption: "Destination", checked: true
    });
    private textboxDays = new Textbox({ id: "textboxDays", width: 300, allowDropdownBlank: false, }); x
    mapOrders: Map;

    private get orderStatus(): OrderStatus {
        return this.tableCustomerOrders.orderStatus;
    }

    private get mainDataSource(): DataSource {
        return this.tableCustomerOrders.mainDataSource;
    }

    constructor(layout: TableCustomerOrders) {
        super({ fillHeight: true, fillRow: true, padding: 0 });
        this.tableCustomerOrders = layout;
        this.addOriginDestPanel();
        this.addMap();
    }

    addOriginDestPanel() {
        this.switchOriginDest.checked = false;
        this.switchOriginDest.addChangeListener((event: ChangeEvent) => this.switchOriginDestOnChange(event));
        this.panelOriginDest.add(this.switchOriginDest);
        if (this.orderStatus != OrderStatus.ALL) {
            this.setDaysCaption();
            this.setTextboxDayItems();
            this.panelOriginDest.add(this.textboxDays);
            this.textboxDays.addChangeListener((event: ChangeEvent) => this.textboxDaysOnChange(event));
        }
        this.add(this.panelOriginDest);
    }

    addMap() {
        this.mapOrders = new Map({
            borderRadius: 4, fillHeight: true, fillRow: true, id: "map1",
            latitudeField: "shipper.latitude", longitudeField: "shipper.longitude",
            pinLayout: "portal-customer/PopupTableCustomerOrders",
            vendor: MapVendor.DEFAULT_DISTANCE_VENDOR
        })
        this.mapOrders.dataSource = this.mainDataSource;
        this.mapOrders.setPopupProps({
            top: window.innerHeight * .25,
            left: window.innerWidth * .025,
            width: window.innerWidth * .95,
            height: window.innerHeight * .65
        });
        this.mapOrders.addPinPlotListener(event => this.processAddedPin(event.pin, this.mainDataSource.data));
        this.add(this.mapOrders);
    }

    processAddedPin(pin: MapPin, rows: ModelRow<any>[]) {
        if (pin?.data == null) { return; }
        const orderId = pin.data.get("orders.id");
        pin.tooltip = orderId;
        pin.image = "map-pin-white-dot-small";
        let count = 0;
        for (let x = 0; x < rows.length; x++) {
            const modelRow = rows[x];
            const latitude = modelRow.get(this.mapOrders.latitudeField);
            const longitude = Math.abs(modelRow.get(this.mapOrders.longitudeField)) * -1;

            if (latitude === pin.latitude && longitude === pin.longitude) {
                count = count + 1;
                if (!pin.rows.some(row => row.get("orders.id") === orderId)) {
                    pin.rows.push(modelRow);
                }
            }
        }
        if (count > 1) {
            pin.tooltip = pin.data.get("shipper_name");
            pin.image = "map-pin-white-dot-large";
            pin.caption = `${count}`;
            pin.tooltip = `${count} orders at this exact location.`;
            pin.captionColor = "Blue";
        }
    }

    textboxDaysOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange)
            this.searchMap();
    }

    private searchMap() {
        this.mainDataSource.search({
            status: this.orderStatus,
            date_offset: this.textboxDays?.selectedItem?.value ?? 0,
            is_origin: this.switchOriginDest.checked ? "N" : "Y"
        });
    }

    switchOriginDestOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            if (this.orderStatus === OrderStatus.PROGRESS)
                this.setTextboxDayItems();
            this.setDaysCaption();

            this.mapOrders.latitudeField = event.newValue ? "consignee.latitude" : "shipper.latitude";
            this.mapOrders.longitudeField = event.newValue ? "consignee.longitude" : "shipper.longitude";
            this.searchMap();
        }
    }

    public setDaysCaption() {
        const dayCaption = this.dayCaptions.find(dayCaptions => this.orderStatus == dayCaptions.orderStatus)
        this.textboxDays.caption = this.switchOriginDest.checked ? dayCaption?.destination : dayCaption?.origin;
    }

    private setTextboxDayItems() {
        let lastItem: DropdownItem = null;
        let futureDate = false;

        if (this.orderStatus === OrderStatus.AVAILABLE) {
            futureDate = true;
            lastItem = { value: "0", caption: "Any day (all available loads)" };
        }
        if (this.orderStatus === OrderStatus.PROGRESS) {
            futureDate = this.switchOriginDest.checked
            lastItem = { value: "0", caption: "Any day (all in progress loads)" };
        }
        const dayPrefix = futureDate ? "next" : "last";
        const items: DropdownItem[] = [
            { value: "1", caption: futureDate ? "Today or tomorrow" : "Yesterday or today" },
            { value: "3", caption: `Within the ${dayPrefix} 3 days` },
            { value: "5", caption: `Within the ${dayPrefix} 5 days` },
            { value: "7", caption: `Within the ${dayPrefix} 7 days` },
            { value: "14", caption: `Within the ${dayPrefix} 14 days` }
        ];
        if (lastItem)
            items.push(lastItem);

        this.textboxDays.items = items;
        this.textboxDays.selectedItem = items[items.length - 1];
    }
}
