import { Button, DataSource, HorizontalSpacer, Image, Label, Layout, Panel, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutCarrierDispatch extends Layout {
    buttonCancel: Button;
    buttonSave: Button;
    horizontalspacer10: HorizontalSpacer;
    horizontalspacer11: HorizontalSpacer;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    horizontalspacer3: HorizontalSpacer;
    horizontalspacer4: HorizontalSpacer;
    horizontalspacer5: HorizontalSpacer;
    horizontalspacer7: HorizontalSpacer;
    horizontalspacer8: HorizontalSpacer;
    horizontalspacer9: HorizontalSpacer;
    imageVan: Image;
    labelDispatchConfirmation: Label;
    labelLocation: Label;
    labelOrderId: Label;
    panel1: Panel;
    panelDispatch: Panel;
    sourceCarrierDispatch: DataSource;
    textboxActualArrivalDate: Textbox;
    textboxComments: Textbox;
    textboxDepartureDate: Textbox;
    textboxSchedDate: Textbox;
}
