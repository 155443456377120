import { Checkbox, Snackbar } from "@mcleod/components";
import { getUnauthSettings } from "@mcleod/core";
import { AutogenLayoutOrderTermsConditions } from "./autogen/AutogenLayoutOrderTermsConditions";

export class OrderTermsConditions extends AutogenLayoutOrderTermsConditions {

    override onLoad() {
        this.textboxTerms.text = getUnauthSettings().company_settings?.["order_terms_conditions"];
    }

    checkboxAgreeOnChange(event) {
        const checkbox = event.target as Checkbox;
        checkbox.color = event.newValue ? "success" : "error";
        checkbox.checkColor = checkbox.color;
    }

    userAgreed(): boolean {
        if (!this.checkboxAgree.checked) {
            Snackbar.showWarningSnackbar({ padding: 0, width: 450, caption: "Please accept Terms and Conditions before proceeding." }, { id: "terms", persist: true });
            return false;
        }
        return true;
    }

}
