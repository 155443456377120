import { CommonDialogs } from "@mcleod/common";
import { Attachment, Button, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, Dialog, Label, Layout, TableRowDisplayEvent } from "@mcleod/components";
import { HorizontalAlignment } from "@mcleod/core";
import { FactoringImages } from "./FactoringImages";
import { AutogenLayoutFactoringOrdersReceivedDetails } from "./autogen/AutogenLayoutFactoringOrdersReceivedDetails";

export class FactoringOrdersReceivedDetails extends AutogenLayoutFactoringOrdersReceivedDetails {

    factoringImageDialog: Dialog;

    public removeTabset() {
        this.remove(this.tabsetOrderDetails);
    }

    public removeFactoringImages() {
        this.tabsetOrderDetails.remove(this.tabImages);
    }

    public removeFactoringComments() {
        this.tabsetOrderDetails.remove(this.tabComments);
    }

    public setSelectedTabset(tabset: string) {
        if (tabset === "Comments") {
            this.tabComments.select();
        }
        if (tabset === "Images") {
            this.tabImages.select();
        }
    }

    public setNoComments() {
        if (this.factoringComments.rows.length > 0) {
            this.labelNoComments.caption = "";
        }
    }

    public setNoImages() {
        if (this.factoringImagesDetailTable.rows.length > 0) {
            this.labelNoImages.caption = "";
        }
    }

    public setlabelCommentStatus(labelCommentStatus: Label) {
        labelCommentStatus.imageColor = "primary";
    }

    public setlabelImageStatus(labelImageStatus: Label) {
        labelImageStatus.imageColor = "primary";
    }

    public setupFactoringImagesLayout(movementId: string, orderId: string, addImageButton: Button, labelImageStatus: Label) {

        addImageButton.addClickListener(event => {
            const layout = Layout.getLayout("portal-factoring/FactoringImages", {
                maxWidth: 1100, width: window.innerWidth * .55,
                backgroundColor: "defaultBackground",
                borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary"
            }) as FactoringImages;

            this.setupFactoringImages(movementId, orderId, layout, labelImageStatus);
            this.factoringImageDialog = CommonDialogs.createDialog(layout, { title: "Upload Images", okVisible: false });
            this.factoringImageDialog.show();
        });
    }

    public setupFactoringImages(movementId: string, orderId: string, layout: FactoringImages, labelImageStatus: Label) {

        layout.addLayoutDataLoadListener(event => {

            this.tabImages.select();
            layout.mainDataSource.mode = DataSourceMode.ADD;

            layout.mainDataSource.addBeforeExecutionListener((event: DataSourceExecutionEvent) => {
                if (event.getAction() === DataSourceAction.ADD) {
                    event.dataSource.activeRow?.set("movement_id", movementId);
                    event.dataSource.activeRow?.set("order_id", orderId);
                }
            });
            layout.buttonCancel.addClickListener(event => {
                this.factoringImageDialog.close();
            });
            layout.buttonSave.addClickListener(event => {
                layout.mainDataSource.addAfterExecutionListener(() => {
                    this.tabImages.dataSource.search({ movement_id: movementId, order_id: orderId })
                    layout.mainDataSource.mode = DataSourceMode.ADD;
                    this.labelNoImages.caption = "";
                    this.setlabelImageStatus(labelImageStatus);
                });
            });
            layout.buttonSaveAndClose.addClickListener(event => {
                layout.mainDataSource.addAfterExecutionListener(() => {
                    this.tabImages.dataSource.search({ movement_id: movementId, order_id: orderId })
                    this.labelNoImages.caption = "";
                    this.setlabelImageStatus(labelImageStatus);
                    this.factoringImageDialog.close();
                });
            });
        });
    }
    imagesOnDisplay(event: TableRowDisplayEvent) {
        const attachment = event.getTableRow().findComponentById("attachmentAttachment") as Attachment;
        if (attachment["_printLabel"]) {
            attachment["_printLabel"].align = HorizontalAlignment.LEFT;
            attachment.minHeight = attachment["_printLabel"].height;
        }
    }
}
