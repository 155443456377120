import { ChangeEvent, Checkbox, DataSourceExecutionEvent, Layout, LookupModelSearchEvent, Snackbar } from "@mcleod/components";
import { DataSource, DataSourceAction } from "@mcleod/components/src/databinding/DataSource";
import { ModelRow, getUnauthSettings } from "@mcleod/core";
import { FtlQuote } from "./FtlQuote";
import { showCreditSnackbar, validateShipper } from "./OrderValidation";
import { AutogenLayoutFtlQuoteDetails } from "./autogen/AutogenLayoutFtlQuoteDetails";
import { LtlUtil } from "./ltl/LtlUtil";

const snackbarPops = { padding: 0, width: 450 };
export class FtlQuoteDetails extends AutogenLayoutFtlQuoteDetails {

    public validateShipperAfterSearch: boolean = true;
    public doBeforeCommoditySearch: (filter: any) => void;

    override onLoad() {
        this.labelTerms.caption = getUnauthSettings().company_settings["order_terms_conditions"] ?? "";
        this.labelOrderSubmitMsg.caption = getUnauthSettings().company_settings["order_submit_ok"] ?? "";
        if (this.owner instanceof FtlQuote) {
            replaceLayoutDataSource(this.layoutQuoteStops, this.layoutQuoteStops.sourceQuoteStops, this.owner.sourceQuoteStops);

            this.layoutQuoteStops.sourceQuoteStops.addAfterExecutionListener((event: DataSourceExecutionEvent) => {
                if (event.getAction() == DataSourceAction.SEARCH) {
                    const index = LtlUtil.isAsset() ? 1 : 2;
                    if (this.validateShipperAfterSearch == true && this.owner.stepper.selectedIndex == index)
                        this.validateShipper();
                }
            })
        }
        this.doBeforeCommoditySearch = (filter: any) => {
            filter.product_book_location = this.layoutQuoteStops.sourceQuoteStops?.data?.[0].get("location_id");
        };
    }

    public doBeforeDisplay(selectedRate: ModelRow<any>) {
        this.checkAgree.checked = false;
        if (this.owner instanceof FtlQuote) {
            this.layoutOverview.setup(this.owner.mainDataSource, selectedRate);
            this.layoutOverview.panelTotal.visible = this.activeRow.data["ready_for_booking"];
        }
    }

    validateShipper(): boolean {
        const shipperLoc = this.layoutQuoteStops.sourceQuoteStops.data[0]?.get("location_id");
        return validateShipper(shipperLoc, this.activeRow.get("id"));
    }

    async validateBeforePost(): Promise<boolean> {
        let result = true;

        await this.layoutQuoteStops.validatePickupTimesBeforePost();

        const validation: (() => boolean)[] = [
            () => this.termsAccepted(),
            () => this.validateShipper(),
            () => this.layoutQuoteStops.hasValidPickupTimes,
            () => showCreditSnackbar(this.activeRow?.getBoolean("credit_status_valid", false), false)
        ]
        for (const isValid of validation)
            result = isValid() && result;
        return result;
    }

    checkAgreeOnChange(event: ChangeEvent) {
        const checkbox = event.target as Checkbox;
        checkbox.color = event.newValue ? "success" : "error";
        checkbox.checkColor = checkbox.color;
    }

    private termsAccepted(): boolean {
        if (this.checkAgree.checked == false)
            Snackbar.showWarningSnackbar({ ...snackbarPops, caption: "Please accept Terms and Conditions before proceeding." }, { id: "terms", persist: true });
        return this.checkAgree.checked;
    }

    textCommodityBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.search_product_book = true;
        if (this.doBeforeCommoditySearch)
            this.doBeforeCommoditySearch(event.filter);
    }
}

function replaceLayoutDataSource(layout: Layout, thisDataSource: DataSource, newDataSource: DataSource) {
    thisDataSource.rebindComponentsTo(newDataSource);
    newDataSource.attachListeners(thisDataSource);
    layout[thisDataSource.id] = newDataSource;
}
