import { Layout, Tab, Tabset } from "@mcleod/components";
import { TableCarrierAccepted } from "../TableCarrierAccepted";
import { TableCarrierAvailable } from "../TableCarrierAvailable";
import { TableCarrierDeliveredOrders } from "../TableCarrierDeliveredOrders";
import { TableCarrierLoadboard } from "../TableCarrierLoadboard";
import { TableCarrierOfferHistory } from "../TableCarrierOfferHistory";

export abstract class AutogenLayoutCarrierPortal extends Layout {
    layout1: TableCarrierAccepted;
    layoutAvailableLoads: TableCarrierAvailable;
    layoutAvailableLoads: TableCarrierLoadboard;
    layoutDeliveredLoads: TableCarrierDeliveredOrders;
    layoutOfferHistory: TableCarrierOfferHistory;
    tabAccepted: Tab;
    tabAvailable: Tab;
    tabDelivered: Tab;
    tabLoadboard: Tab;
    tabOfferHistory: Tab;
    tabset: Tabset;
}
