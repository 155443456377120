import { Button, ButtonVariant, ChangeEvent, ClickEvent, DataDisplayEvent, DataSourceExecutionEvent, Label, Panel, Snackbar, TableColumn, TableRow, TableRowExpansionEvent, Textbox } from "@mcleod/components";
import { Api, DateUtil, FileUtil, ModelRow, getAuthSettings } from "@mcleod/core";
import { CarrierDeliveredOrdersDetails } from "./CarrierDeliveredOrdersDetails";
import { AutogenLayoutTableCarrierDeliveredOrders } from "./autogen/AutogenLayoutTableCarrierDeliveredOrders";

export class TableCarrierDeliveredOrders extends AutogenLayoutTableCarrierDeliveredOrders {

    private allColumns: TableColumn[];
    private unpaidExcludeColumns = ["cellCheckNumber", "checkAmount", "cellPaidShipDate"];
    private paidExcludeColumns = ["cellUnpaidShipDate"];

    override onLoad() {
        this.allColumns = [...this.tableCarrierDeliveredOrders.columns];
        this.tableCarrierDeliveredOrders.toolsPanel.rowBreak = false;
        this.switchUnpaid.checked = true;
        this.mainDataSource.addBeforeExecutionListener((event: DataSourceExecutionEvent) => event.filter.type = this.switchUnpaid.checked ? "unpaid" : "paid");
    }

    public orderExpanded(event: TableRowExpansionEvent) {
        const tableRow = event.getTableRow();
        const orderModelRow = event.getTableRow()?.data as ModelRow;
        const movementId = orderModelRow?.get("movement_id");
        const orderId = orderModelRow?.get("order_id");

        const expandPanel = event.expandComponentParent as Panel;
        const carrierDeliveredOrdersDetailsLayout = expandPanel.findComponentById("layoutOrderDetails") as CarrierDeliveredOrdersDetails;
        const addImagesButton = expandPanel.findComponentById("buttonAddImages") as Button;

        expandPanel.remove(expandPanel.findComponentById("buttonAddCallins"));
        const imagePermission = getAuthSettings().permissions?.denied?.InternetModule?.["InternetModuleDocumentRetrieval"] == null

        carrierDeliveredOrdersDetailsLayout.addLayoutLoadListener(event => {
            if (imagePermission === true) {
                carrierDeliveredOrdersDetailsLayout.removeCarrierCallins();
                carrierDeliveredOrdersDetailsLayout.tabImages.dataSource.search({ movement_id: movementId, order_id: orderId }).then(
                    result => {
                        carrierDeliveredOrdersDetailsLayout.setNoImages();
                    }
                );
                carrierDeliveredOrdersDetailsLayout.setupCarrierImagesLayout(movementId, orderId, addImagesButton, tableRow.findComponentById("labelImageStatus") as Label);
            }
            else {
                expandPanel.remove(addImagesButton);
                carrierDeliveredOrdersDetailsLayout.removeTabset();
            }
        });

        const settlementDwnldPerm = getAuthSettings().permissions?.denied?.InternetModule?.["InternetModuleCarrierSettlementHistory"] !== 0;
        const toolsProps = { variant: ButtonVariant.text, color: "primary", dropped: false, caption: "Settlement Summary" };
        if ((event.target as TableRow).data != null) {
            const modelRow = (event.target as TableRow).data;
            if (settlementDwnldPerm === true && this.switchUnpaid.checked == false && modelRow?.get("check_number") != null) {
                addImagesButton.rowBreak = false;
                const settlementButton = new Button({ ...toolsProps, tooltip: "Download settlement summary report", imageName: "document", rowBreak: true });
                expandPanel.insert(settlementButton, 5);
                settlementButton.addClickListener((event: ClickEvent) => {
                    this.generateSettlementReport(modelRow);
                })
            }
        }

        const tractor = expandPanel.findComponentById("textboxTractor");
        const trailer = expandPanel.findComponentById("textboxTrailer");
        if (tractor != null && tractor instanceof Textbox)
            tractor.width = this.tableCarrierDeliveredOrders.columns[1]?.headingCell?._element?.offsetWidth;
        if (trailer != null && trailer instanceof Textbox) {
            trailer.width = this.tableCarrierDeliveredOrders.columns[2]?.headingCell?._element?.offsetWidth
        }
    }

    switchUnpaidOnChange(event: ChangeEvent) {
        this.setupTableColumns(event.newValue);
        if (event.userInitiatedChange)
            this.mainDataSource.search({});
    }

    public setupTableColumns(unpaid: boolean) {
        const excludeColumns = unpaid ? this.unpaidExcludeColumns : this.paidExcludeColumns;
        this.tableCarrierDeliveredOrders.clearColumns();
        for (let x = 0; x < this.allColumns.length; x++) {
            const column = this.allColumns[x];
            const cellId = column?.cellDef?.def?.id;
            if ("cellBalance" == cellId)
                column.headingCell.caption = unpaid ? "Balance" : "Check Amount"

            if (!excludeColumns.includes(cellId))
                this.tableCarrierDeliveredOrders.addColumn(this.allColumns[x], x == 0, "cellActions" == cellId);
        }
        this.tableCarrierDeliveredOrders.resetColumnSorting();
    }


    labelImageStatusOnDataDisplay(event: DataDisplayEvent) {
        if (event.rowData?.getBoolean("has_images"))
            (event.target as Label).imageColor = "primary";
    }

    labelImageStatusOnClick(event: ClickEvent) {
        const tableRow = event.target["row"] as TableRow
        tableRow.setExpanded(true);
    }

    generateSettlementReport(modelRow: ModelRow) {
        const checkNum = modelRow?.get("check_number");
        if (checkNum != null && modelRow?.get("check_date") != null) {
            const checkDate = DateUtil.formatDate(DateUtil.parseDate(modelRow?.get("check_date")), "MM/dd/yyyy");
            const filter = [
                {
                    check_number: checkNum,
                    check_date: checkDate
                }
            ];
            const body = JSON.stringify(filter);
            const progressSnack: Snackbar = Snackbar.showDownloadSnackbar("Settlement Summary", "We are generating your settlement summary and it will download automatically.");
            Api.post("portal/reports/settlement-summary", body).then(response => {
                if (response != null) {
                    FileUtil.downloadBase64AsFile(response.data[0].settlement_summary_report, `Settlement Summary ${checkDate}.pdf`);
                    Snackbar.showSnackbar("Downloaded " + `Settlement Summary ${checkDate}.pdf`);
                    progressSnack.dismiss();
                }
            });
        }
    }
}
