import { Button, ChangeEvent, ClickEvent, Component, DataSourceExecutionEvent, DataSourceMode, Layout, Panel, Step, StepEvent, Stepper, Switch, Table, TableAddRowResult, TableRow, TableRowCreationEvent, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { DateUtil, Navigation, getLogger, getThemeColor, getUnauthSettings } from "@mcleod/core";
import { DriverEmploymentHistory } from "./DriverEmploymentHistory";
import { AutogenLayoutDriverApplication } from "./autogen/AutogenLayoutDriverApplication";

const log = getLogger("portal-driver/DriverApplication");

export class DriverApplication extends AutogenLayoutDriverApplication {

    private layoutPersonalInfo: Layout;

    override onLoad() {
        this.stepperDriverApp.cancel = (() => {
            Navigation.navigateTo("common/Login");
        });
        DateUtil.setUserSettings("MM/dd/yyyy", "HH:mm", "MM/dd/yyyy HH:mm");
        const tableJobExp = this.stepperDriverApp.findComponentById("tableJobExp") as Table
        this.sourceDriverApplication.createBlankRow().then(row => {
            row._appending = true;
            this.mainDataSource.rowIndex = 0;
            this.mainDataSource.data = [row];
            this.mainDataSource.mode = DataSourceMode.ADD;
            //this.addNewJobExpToTable(tableJobExp);
        });

        const agreeButton = this.stepperDriverApp.findComponentById("buttonAgree") as Button;
        const nextButton = (this.stepperDriverApp as Stepper)["buttonNext"];
        agreeButton.addClickListener((event: ClickEvent) => {
            (event.target as Button).color = getThemeColor("success");
            (event.target as Button).imageName = "circleCheckEmpty";
            nextButton.enabled = true;
        });

        (this.stepperDriverApp.findComponentById("panelOwner") as Panel).visible = false;
        const ownerOperatorSwitch = this.stepperDriverApp.findComponentById("switchOwnerOperator") as Switch;
        ownerOperatorSwitch.addChangeListener((event: ChangeEvent) => {
            const ownerPanel = this.stepperDriverApp.findComponentById("panelOwner") as Panel;
            if (event.newValue == true)
                ownerPanel.visible = true;
            else
                ownerPanel.visible = false;
        });
        const addJobButton = this.stepperDriverApp.findComponentById("buttonAddJob") as Button;
        addJobButton.addClickListener((event: ClickEvent) => {
            this.addNewJobExpToTable(tableJobExp);
        });
        const felonyQuestPanel = this.stepperDriverApp.findComponentById("panelFelonyConviction") as Panel;


        const disableFelonyQuestions = getUnauthSettings()?.company_settings["suppress_felony"];
        if (disableFelonyQuestions != null && disableFelonyQuestions == true)
            felonyQuestPanel.visible = false;

        this.setPersonalInfoCaptions();
        this.setupCertificateStep();
        this.setupRecordsStep();

        tableJobExp.addRowDisplayListener((event: TableRowDisplayEvent) => {
            const toolCell = (event.target as TableRow).cells[1];
            toolCell.addMountListener(() => {
                event.getTable().rows.forEach(row => {
                    row.cells[1].width = 100;
                });
            })
            toolCell.addResizeListener(() => {
                if (toolCell.width == 76) //width of 76 cuts off save/cancel buttons
                    toolCell.width = 100;
            })
            //Note: this was created to make row printable again after deleting another row
            if (event.getTableRow().mode == TableRowMode.NONE) {
                const tableRow = event.getTableRow();
                if (tableRow.findComponentById("panelJobExp") != null) {
                    const panelJobExp = tableRow.findComponentById("panelJobExp");
                    panelJobExp.addMountListener(() => {
                        const layout = Layout.getLayout("portal-driver/DriverEmploymentHistory") as DriverEmploymentHistory;
                        layout.addLayoutLoadListener(() => {
                            tableRow.displayComponentValues();
                            if (tableRow.rowIsPrintable() == true) {
                                tableRow.forEveryChildComponent((component: Component) => {
                                    if (component instanceof Textbox)
                                        (component as Textbox).printable = true;
                                })
                            }
                        })
                    });
                }
            }


        });

        tableJobExp.addRowCreateListener((event: TableRowCreationEvent) => {
            log.debug("row create event", { event })
        })

        tableJobExp.doOnRowDelete = (oRow) => {
            log.debug("oRow delete", { oRow })
        }

        this.sourceDriverEmpHistory.addAfterExecutionListener((event: DataSourceExecutionEvent) => {
            log.debug({ event })
        });
    }


    /** This is an event handler for the beforeStepListener event of stepperDriverApp.  */
    stepperDriverAppBeforeStepListener(event: StepEvent) {
        const stepIndex = event.newIndex;
        const lastStepIndex = this.stepperDriverApp.getComponentCount() - 1;
        const buttonNext = event.target["buttonNext"] as Button;
        const oldStep = event.oldStep as Step;
        if (stepIndex == lastStepIndex) {
            buttonNext.enabled = false;
        }
        else
            buttonNext.enabled = true;

        if (event.isSubmit() == true) {
            //let linked = this.mainDataSource.activeRow.getBodyWithLinkedModels();
            const activeRow = this.sourceDriverEmpHistory.activeRow;
            this.mainDataSource.activeRow.addLinkedModel({ model: this.sourceDriverEmpHistory.url, rows: this.sourceDriverEmpHistory.data });
            //linked = this.mainDataSource.activeRow.getBodyWithLinkedModels();
            this.mainDataSource.post().then(response => {
                Navigation.navigateTo("portal-driver/DriverApplicationCongrats");
            });
        }
    }

    async addNewJobExpToTable(jobExpTable: Table) {
        await jobExpTable.dataSource.createBlankRow().then(row => {
            row._appending = true;
            const addRowResult: TableAddRowResult = jobExpTable.addRow(row, { mode: TableRowMode.ADD }, { display: true, addToData: true, save: false });
        });
    }

    setPersonalInfoCaptions() {
        const stepPersonalInfo = (this.stepperDriverApp as Stepper).findComponentById("stepPersonalInfo") as Step;
        const enableCanadianApp = getUnauthSettings()?.company_settings["canadian_drapp"];
        if (enableCanadianApp != null && enableCanadianApp == true) {
            (stepPersonalInfo.findComponentById("textboxDriverZip") as Textbox).caption = "Postal Code";
            (stepPersonalInfo.findComponentById("switchCitizen") as Textbox).caption = "Are you a Canadian citizen?";
            (stepPersonalInfo.findComponentById("switchRightToWork") as Textbox).caption = "Do you have the legal right to work in Canada?";
            (stepPersonalInfo.findComponentById("textboxSocialSecurityNo") as Textbox).caption = "Social Insurance Number"
        }
        else if (enableCanadianApp != null && enableCanadianApp == false) {

            (stepPersonalInfo.findComponentById("textboxDriverZip") as Textbox).caption = "Zip Code";
            (stepPersonalInfo.findComponentById("switchCitizen") as Textbox).caption = "Are you a United States citizen?";
            (stepPersonalInfo.findComponentById("textboxSocialSecurityNo") as Textbox).caption = "Social Security Number";
            (stepPersonalInfo.findComponentById("switchRightToWork") as Textbox).caption = "Do you have the legal right to work in the United States?";
        }
    }

    setupCertificateStep() {
        const stepCertificate = (this.stepperDriverApp as Stepper).findComponentById("stepCertificate") as Step;//.components[1]//["stepCertificate"];
        const licRevokedReason = stepCertificate.findComponentById("textboxLicenseRevokedD") as Textbox;
        licRevokedReason.enabled = false;
        (stepCertificate.findComponentById("switchLicenseRevoked") as Switch).addChangeListener((event: ChangeEvent) => {
            if (event.newValue == true)
                licRevokedReason.enabled = true;
            else {
                licRevokedReason.enabled = false;
                licRevokedReason.text = "";
            }
        });
    }

    setupRecordsStep() {
        const stepRecords = (this.stepperDriverApp as Stepper).findComponentById("stepRecords") as Step;//.components[2]//["stepRecords"];
        const convictionReason = stepRecords.findComponentById("textboxConvictFelonyD") as Textbox;
        convictionReason.enabled = false;
        (stepRecords.findComponentById("switchConvictFelony") as Switch).addChangeListener((event: ChangeEvent) => {
            if (event.newValue == true)
                convictionReason.enabled = true;
            else {
                convictionReason.enabled = false;
                convictionReason.text = "";
            }
        });
    }
}
