import { DataSource, Layout, Panel, PortalLocation, SearchButton, Switch, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutAdvancedSearch extends Layout {
    location: PortalLocation;
    panelSearch: Panel;
    searchbuttonSearch: SearchButton;
    sourceOrders: DataSource;
    switchDate: Switch;
    textboxConsigneeRefno: Textbox;
    textboxShipDateFrom: Textbox;
    textboxShipDateTo: Textbox;
    textboxStatus: Textbox;
}
