import { Button, Checkbox, CityState, DataSource, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutLocationManualAdd extends Layout {
    buttonCancel: Button;
    buttonUseThisAddress: Button;
    checkboxSave: Checkbox;
    citystate1: CityState;
    panel1: Panel;
    sourceLocation: DataSource;
    textboxCompanyName: Textbox;
    textboxStreetName: Textbox;
}
