import { Snackbar } from "@mcleod/components";
import { Api, DateUtil, FileUtil } from "@mcleod/core";
import { AutogenLayoutARAgingReport } from "./autogen/AutogenLayoutARAgingReport";

export class ARAgingReport extends AutogenLayoutARAgingReport {

    override onLoad() {
        this.textboxReportBy.selectedItem = { caption: "Ship Date" };
        this.textboxReportType.selectedItem = { caption: "Detailed" };
    }

    generateReport() {

        const todaysDate = DateUtil.formatDate(new Date(), "MM/dd/yyyy");
        const filter = [
            {
                report_by: this.textboxReportBy.selectedItem.caption,
                report_type: this.textboxReportType.selectedItem.caption,
                invoice_cutoff: this.textboxInvoiceCutoff.text,
                payment_cutoff: this.textboxPaymentCutoff.text,
                age_date: this.textboxAgeDate.text,
                past_dues: this.checkboxPastDue.checked,
                include_open_credits: this.checkboxOpenCredits.checked
            }
        ];
        const body = JSON.stringify(filter);
        const progressSnack: Snackbar = Snackbar.showDownloadSnackbar("AR Aging Report", "We are generating your AR Aging Report and it will download automatically.", { persist: true });
        Api.post("portal/reports/ar-aging-report", body).then(response => {
            if (response != null) {
                FileUtil.downloadBase64AsFile(response.data[0].ar_aging_report, `AR Aging Report ${todaysDate}.pdf`);
                Snackbar.showSnackbar("Downloaded " + `AR Aging Report ${todaysDate}.pdf`);
                progressSnack.dismiss();
            }
        });
    }
}
