import { Button, ClickEvent, DataDisplayEvent, KeyEvent, Label, Panel, TableRow, TableRowExpansionEvent } from "@mcleod/components";
import { Keys, StringUtil, getAuthSettings, getUnauthSettings } from "@mcleod/core";
import { FactoringOrdersReceivedDetails } from "./FactoringOrdersReceivedDetails";
import { PaymentsReceivedDetails } from "./PaymentsReceivedDetails";
import { AutogenLayoutTablePaymentsReceived } from "./autogen/AutogenLayoutTablePaymentsReceived";

export class TablePaymentsReceived extends AutogenLayoutTablePaymentsReceived {

    factoringOrdersDetailsLayout: FactoringOrdersReceivedDetails;
    tabSelected: string;

    override onLoad() {
        this.mainDataSource.search();
        const searchDays = getUnauthSettings().company_settings["factoring_search_days"];
        this.textboxDaysofChecks.placeholder = searchDays;
    }

    /** This is an event handler for the onRowExpand event of tablePaymentsReceived.  */
    tablePaymentsReceivedOnRowExpand(event: TableRowExpansionEvent) {
        const tableData = event.getTableRow().data;
        const expandPanel = event.expandComponentParent as Panel;
        const labelCommentStatus = event.getTableRow().cells[8].components[1] as Label;
        const labelImageStatus = event.getTableRow().cells[8].components[2] as Label;
        const movementId = tableData?.get("movement_id");
        const orderId = tableData?.get("order_number");
        const settleHistId = tableData?.get("settle_hist_id");
        const addImagesToolPanel = expandPanel.findComponentById("panelImageTools") as Panel;
        const addImagesButton = addImagesToolPanel.findComponentById("buttonAddImages") as Button;

        const paymentsReceivedDetailLayout = expandPanel.findComponentById("layoutDetails") as PaymentsReceivedDetails;
        paymentsReceivedDetailLayout.addLayoutLoadListener(event => {
            paymentsReceivedDetailLayout.sourceFactoringPaymentDetails.search({ settle_hist_id: tableData?.get("settle_hist_id") });

            this.factoringOrdersDetailsLayout = paymentsReceivedDetailLayout.findComponentById("layout1") as FactoringOrdersReceivedDetails;
            const imagePermission = getAuthSettings().permissions?.denied?.InternetModule?.["InternetModuleDocumentRetrieval"] == null
            const commentPermission = true;

            this.factoringOrdersDetailsLayout.addLayoutLoadListener(event => {
                //this.factoringOrdersDetailsLayout.tabset_.borderBottomWidth = 0;
                this.factoringOrdersDetailsLayout.setSelectedTabset(this.tabSelected);
                if (commentPermission) {
                    this.factoringOrdersDetailsLayout.factoringComments.dataSource.search({ order_id: orderId, id: settleHistId }).then(
                        result => {
                            this.factoringOrdersDetailsLayout.setNoComments();
                        }
                    );
                }
                else {
                    this.factoringOrdersDetailsLayout.removeFactoringComments();
                }

                if (imagePermission) {
                    this.factoringOrdersDetailsLayout.tabImages.dataSource.search({ movement_id: movementId, order_id: orderId }).then(
                        result => {
                            this.factoringOrdersDetailsLayout.setNoImages();
                        }
                    );
                    this.factoringOrdersDetailsLayout.setupFactoringImagesLayout(movementId, orderId, addImagesButton, labelImageStatus);
                }
                else {
                    expandPanel.remove(addImagesToolPanel);
                    this.factoringOrdersDetailsLayout.removeFactoringImages();
                }
            });
        });
    }

    labelCommentStatusOnClick(event: ClickEvent) {
        this.tabSelected = "Comments";
        this.setTabset(event);
    }

    labelImageStatusOnClick(event: ClickEvent) {
        this.tabSelected = "Images";
        this.setTabset(event);
    }

    setTabset(event: ClickEvent) {
        const tableRow = event.target["row"] as TableRow
        if (tableRow.expanded === true) {
            this.factoringOrdersDetailsLayout.setSelectedTabset(this.tabSelected);
        }
        else {
            tableRow.setExpanded(true);
        }
    }

    label_mailOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label
        const email = event.rowData.get("contact_email");
        const invoice = event.rowData.get("carrier_invoice");
        if (email == null) return;

        label.link = "mailto:" + email + "?subject=Question about "
            + event.rowData.get("carrier_name") + " Order: " + event.rowData.get("order_number");
        if (invoice != null)
            label.link = label.link + " Invoice: " + invoice;
    }

    labelCommentStatusOnDataDisplay(event: DataDisplayEvent) {
        const count = event.rowData.get("comments_count");
        if (count > 0) {
            (event.target as Label).imageColor = "primary";
        }
        else {
            (event.target as Label).imageColor = "default.lightest";
        }
    }

    labelImageStatusOnDataDisplay(event: DataDisplayEvent) {
        const count = event.rowData.get("images_count");
        if (count > 0) {
            (event.target as Label).imageColor = "primary";
        }
        else {
            (event.target as Label).imageColor = "default.lightest";
        }
    }


    /** This is an event handler for the onClick event of searchbuttonSearch.  */
    searchbuttonSearchOnClick(event: ClickEvent) {
        this.searchDaysBack();
    }

    /** This is an event handler for the onKeyDown event of textboxDaysofChecks.  */
    textboxDaysofChecksOnKeyDown(event: KeyEvent) {
        if (event.key == Keys.ENTER) {
            this.searchDaysBack();
        }
        if (event.key == Keys.ARROW_UP) {
            if (StringUtil.isEmptyString(this.textboxDaysofChecks.text) || isNaN(Number(this.textboxDaysofChecks.text)))
                this.textboxDaysofChecks.text = String(this.textboxDaysofChecks.minValue);
            else {
                if (Number(this.textboxDaysofChecks.text) >= this.textboxDaysofChecks.maxValue)
                    this.textboxDaysofChecks.text = String(this.textboxDaysofChecks.maxValue);
                else
                    this.textboxDaysofChecks.text = String(Number(this.textboxDaysofChecks.text) + 1);
            }
        }
        if (event.key == Keys.ARROW_DOWN) {
            const value = this.textboxDaysofChecks.text;
            if (StringUtil.isEmptyString(this.textboxDaysofChecks.text) || isNaN(Number(this.textboxDaysofChecks.text)))
                this.textboxDaysofChecks.text = String(this.textboxDaysofChecks.minValue);
            else {
                if (Number(this.textboxDaysofChecks.text) <= this.textboxDaysofChecks.minValue)
                    this.textboxDaysofChecks.text = String(this.textboxDaysofChecks.minValue);
                else
                    this.textboxDaysofChecks.text = String(Number(this.textboxDaysofChecks.text) - 1);
            }
        }
    }

    private searchDaysBack() {
        if (!StringUtil.isEmptyString(this.textboxDaysofChecks.valueAsString)) {
            const value = Number(this.textboxDaysofChecks.text);
            if (!isNaN(+value)) {
                if (value > this.textboxDaysofChecks.maxValue)
                    this.textboxDaysofChecks.text = String(this.textboxDaysofChecks.maxValue);
                if (value < this.textboxDaysofChecks.minValue)
                    this.textboxDaysofChecks.text = String(this.textboxDaysofChecks.minValue);
                this.mainDataSource.search({ days_of_checks: this.textboxDaysofChecks.valueAsString });
            }
        }
    }
}
