import { DataSource, DataSourceMode, DataSourceModeChangeEvent, ParentSearchMode, Table } from "@mcleod/components";
import { AutogenLayoutQuoteFreightItemTable } from "./autogen/AutogenLayoutQuoteFreightItemTable";

const fgiCommodityFields = ["nmfc_class_code", "_lookup_nmfc_class_code", "fgi_packaging_type_code", "_lookup_fgi_packaging_type_code",
    "length", "height", "width", "description", "nmfc_code", "_lookup_nmfc_code",
    "weight", "weight_uom_type_code", "req_spots", "handling_units",
    "hazmat", "hazmat_number", "hazmat_class_code", "_lookup_hazmat_class_code",
    "hazmat_packing_group", "lookup_hazmat_packing_group", "hazmat_proper_shipname", "hazmat_erg_number"];


export class QuoteFreightItemTable extends AutogenLayoutQuoteFreightItemTable {

    onLoad() {
        this.layoutFreightItemTable.tableDataSource = this.sourceQuoteFreightGroupItem;
    }

    public set doBeforeCommoditySearch(callback: (filter: any) => void) {
        this.layoutFreightItemTable.doBeforeCommoditySearch = callback;
    }

    public get tableFreightItems(): Table {
        return this.layoutFreightItemTable.tableFreightItems;
    }

    public set sourceQuote(sourceQuote: DataSource) {
        this.sourceQuoteFreightGroupItem.parentDataSource = sourceQuote;
        this.sourceQuoteFreightGroupItem.parentSearchMode = ParentSearchMode.onParentDisplay;
        sourceQuote.addAfterModeChangeListener((event: DataSourceModeChangeEvent) => {
            this.sourceQuoteFreightGroupItem.mode = event.newMode;
            if (event.newMode == DataSourceMode.ADD) {
                this.layoutFreightItemTable.addFreightItemToTable();
            }
        })
    }

    public set showDetails(value: boolean) {
        this.layoutFreightItemTable.showDetails = value;
    }

    validateBeforePost(): boolean {
        return this.layoutFreightItemTable.validateBeforePost();
    }

}
