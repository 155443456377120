import { Button, HorizontalSpacer, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutCarrierOfferAcceptPdfDialog extends Layout {
    buttonAccept: Button;
    buttonCancel: Button;
    buttonDownloadRateConfirmation: Button;
    buttonDownloadTerms: Button;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    horizontalspacer3: HorizontalSpacer;
    labelAccept: Label;
    labelOrderConfirmationandTerms: Label;
    panel4: Panel;
    panelPdf: Panel;
}
