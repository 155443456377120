import { Button, HorizontalSpacer, Label, Layout, Panel, Table } from "@mcleod/components";

export abstract class AutogenLayoutFreightItemTable extends Layout {
    buttonAddItem: Button;
    horizontalspacer1: HorizontalSpacer;
    labelFreightInformation: Label;
    labelFreightTotals: Label;
    labelHandlingUnitsCaption: Label;
    labelTotalUnits: Label;
    labelTotalWeight: Label;
    labelWeightCaption: Label;
    panelFrightInfo: Panel;
    tableFreightItems: Table;
}
