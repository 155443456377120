import { Checkbox, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";
import { HandlingRequirements } from "../HandlingRequirements";
import { LtlQuoteStopDetails } from "../LtlQuoteStopDetails";
import { QuoteAndOrderOverview } from "../QuoteAndOrderOverview";
import { QuoteFreightItemTable } from "../QuoteFreightItemTable";

export abstract class AutogenLayoutLtlQuoteDetails extends Layout {
    checkAgree: Checkbox;
    labelAddlDetails: Label;
    labelOrderConfirmation: Label;
    labelOrderSubmitMsg: Label;
    labelTerms: Label;
    labelTermsTitle: Label;
    layoutFreightItems: QuoteFreightItemTable;
    layoutHdr: HandlingRequirements;
    layoutOverview: QuoteAndOrderOverview;
    layoutQuoteStops: LtlQuoteStopDetails;
    panelAcc: Panel;
    panelAddlDetails: Panel;
    panelConfirm: Panel;
    panelDetailsLeft: Panel;
    panelDetailsRight: Panel;
    panelTerms: Panel;
    sourceLtlQuoteOrder: DataSource;
    textboxBlnum: Textbox;
    textboxBolNote: Textbox;
    textboxConsigneeRefno: Textbox;
    textboxPlanningComment: Textbox;
    textboxShipperPo: Textbox;
    textboxShipperRef: Textbox;
}
