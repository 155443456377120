import { ClickEvent, DataDisplayEvent, DataSourceMode, Label, Panel, Snackbar, TableCell } from "@mcleod/components";
import { SortSelector } from "@mcleod/components/src/components/table/SortSelector";
import { Api, DateUtil, FileUtil, HorizontalAlignment, ModelRow, getAuthSettings, getUnauthSettings } from "@mcleod/core";
import { AutogenLayoutPortalSettlementHistory } from "./autogen/AutogenLayoutPortalSettlementHistory";

export class PortalSettlementHistory extends AutogenLayoutPortalSettlementHistory {

    override onLoad() {
        const userSettings = getAuthSettings().user_settings;
        if (userSettings != null && userSettings.user_type == "D") {
            this.textboxOrderId.caption = "Order";
            const heading = this.tableSettlement.columns[0].heading as TableCell;
            const sort = heading.components[0] as SortSelector;
            if (sort != null)
                (sort.components[0] as Label).caption = "Order"; //change column header caption
            const cell = this.tableSettlement.columns[0].cellDef.def.components[0];
            cell.displayLabel = "Order" // change column caption label in Excel Export
        }
        const emptyPanel = new Panel({ fillRow: true, align: HorizontalAlignment.CENTER, marginTop: 20 });
        emptyPanel.add(new Label({ caption: "No settlement records found", fontBold: true }));
        this.tableSettlement.emptyComponent = emptyPanel;
        const settings = getUnauthSettings()?.company_settings;
        if (settings["is_ltl"] != null && settings["is_ltl"] == false) {
            this.textboxCarrierProNbr.visible = false;
            this.tableSettlement.removeColumn(this.tableSettlement.columns.length - 1); //remove carrier pro number, is there a better way??
        }
        this.mainDataSource.search(this.mainDataSource.getDataboundValues(DataSourceMode.SEARCH, null, true));
    }

    /** This is an event handler for the onClick event of labelCheckDate.  */
    labelCheckDateOnClick(event: ClickEvent) {
        const modelRow = event.target["row"]?.data;
        this.generateSettlementReport(modelRow);
    }

    generateSettlementReport(modelRow: ModelRow) {
        const checkNum = modelRow?.get("check_number");
        if (checkNum != null && modelRow?.get("pay_date") != null) {
            const checkDate = DateUtil.formatDate(DateUtil.parseDate(modelRow?.get("pay_date")), "MM/dd/yyyy");
            const filter = [
                {
                    check_number: checkNum,
                    check_date: checkDate
                }
            ];
            const body = JSON.stringify(filter);
            const progressSnack: Snackbar = Snackbar.showDownloadSnackbar("Settlement Summary", "We are generating your settlement summary and it will download automatically.");
            Api.post("portal/reports/settlement-summary", body).then(response => {
                if (response != null) {
                    FileUtil.downloadBase64AsFile(response.data[0].settlement_summary_report, `Settlement Summary ${checkDate}.pdf`);
                    Snackbar.showSnackbar("Downloaded " + `Settlement Summary ${checkDate}.pdf`);
                    progressSnack.dismiss();
                }
            });
        }
    }

    /** This is an event handler for the onDataDisplay event of labelCheckDate.  */
    labelCheckDateOnDataDisplay(event: DataDisplayEvent) {
        const settlementDwnldPerm = getAuthSettings().permissions?.denied?.InternetModule?.["InternetModuleCarrierSettlementHistory"] !== 0;
        const labelCheckDate = (event.target as Label);
        if (settlementDwnldPerm === false) {
            labelCheckDate.visible = false;
        }
        const checkDate = event.rowData?.get("pay_date");
        if (checkDate == null) {
            labelCheckDate.imageName = null;
        }


    }

    /** This is an event handler for the onClick event of buttonSearch.  */
    buttonSearchOnClick(event: ClickEvent) {
        if (this.validateSimple() == true)
            this.mainDataSource.search(this.mainDataSource.getDataboundValues(DataSourceMode.SEARCH, null, true));
        else {

            if (this.textboxPayDateStart.validateSimple() == false) {
                this.textboxPayDateStart.borderColor = "error";
                this.textboxPayDateStart.borderWidth = 1;
            }

            if (this.textboxPayDateEnd.validateSimple() == false) {
                this.textboxPayDateEnd.borderColor = "error";
                this.textboxPayDateEnd.borderWidth = 1;
            }
        }
    }


    /** This is an event handler for the onDataDisplay event of tableSettlement.  */
    tableSettlementOnDataDisplay(event: DataDisplayEvent) { }
}
