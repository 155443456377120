
import { CommonDialogs } from "@mcleod/common";
import { Attachment, Button, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, Dialog, Label, Layout, TableRowDisplayEvent } from "@mcleod/components";
import { HorizontalAlignment } from "@mcleod/core";
import { CarrierCallins } from "./CarrierCallins";
import { CarrierImages } from "./CarrierImages";
import { AutogenLayoutCarrierDeliveredOrdersDetails } from "./autogen/AutogenLayoutCarrierDeliveredOrdersDetails";

export class CarrierDeliveredOrdersDetails extends AutogenLayoutCarrierDeliveredOrdersDetails {

    carrierImageDialog: Dialog;
    carrierCallinDialog: Dialog;

    public removeTabset() {
        this.remove(this.tabset_);
    }

    public removeCarrierImages() {
        this.tabset_.remove(this.tabImages);
    }

    public removeCarrierCallins() {
        this.tabset_.remove(this.tabCallins);
    }

    public setSelectedTabset(tabset: string) {
        if (tabset === "Callins") {
            this.tabCallins.select();
        }
        if (tabset === "Images") {
            this.tabImages.select();
        }
    }

    public setNoCallins() {
        if (this.carrierCallins.rows.length > 0) {
            this.labelNoCallins.caption = "";
        }
    }

    public setNoImages() {
        if (this.carrierImagesDetailTable.rows.length > 0) {
            this.labelNoImages.caption = "";
        }
    }

    public setlabelCallinStatus(labelCallinStatus: Label) {
        labelCallinStatus.imageColor = "primary";
    }

    public setlabelImageStatus(labelImageStatus: Label) {
        labelImageStatus.imageColor = "primary";
    }

    public setupCarrierImagesLayout(movementId: string, orderId: string, addImageButton: Button, labelImageStatus: Label) {

        addImageButton.addClickListener(event => {
            const layout = Layout.getLayout("portal-carrier/CarrierImages", {
                maxWidth: 1100, width: window.innerWidth * .55,
                backgroundColor: "defaultBackground",
                borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary"
            }) as CarrierImages;

            this.setupCarrierImages(movementId, orderId, layout, labelImageStatus);
            this.carrierImageDialog = CommonDialogs.createDialog(layout, { title: "Upload Images", okVisible: false });
            this.carrierImageDialog.show();
        });
    }

    public setupCarrierImages(movementId: string, orderId: string, layout: CarrierImages, labelImageStatus: Label) {

        layout.addLayoutDataLoadListener(event => {

            this.tabImages.select();
            layout.mainDataSource.mode = DataSourceMode.ADD;

            layout.mainDataSource.addBeforeExecutionListener((event: DataSourceExecutionEvent) => {
                if (event.getAction() === DataSourceAction.ADD) {
                    event.dataSource.activeRow?.set("movement_id", movementId);
                    event.dataSource.activeRow?.set("order_id", orderId);
                }
            });
            layout.buttonCancel.addClickListener(event => {
                this.carrierImageDialog.close();
            });
            layout.buttonSave.addClickListener(event => {
                layout.mainDataSource.addAfterExecutionListener(() => {
                    this.tabImages.dataSource.search({ movement_id: movementId, order_id: orderId })
                    layout.mainDataSource.mode = DataSourceMode.ADD;
                    this.labelNoImages.caption = "";
                    this.setlabelImageStatus(labelImageStatus);
                });
            });
            layout.buttonSaveAndClose.addClickListener(event => {
                layout.mainDataSource.addAfterExecutionListener(() => {
                    this.tabImages.dataSource.search({ movement_id: movementId, order_id: orderId })
                    this.labelNoImages.caption = "";
                    this.setlabelImageStatus(labelImageStatus);
                    this.carrierImageDialog.close();
                });
            });
        });
    }


    public setupCarrierCallinsLayout(movementId: string, orderId: string,
        pickupLoc: string, deliveryLoc: string, commodity: string, addCallinButton: Button, labelCallinStatus: Label) {

        addCallinButton.addClickListener(event => {
            const layout = Layout.getLayout("portal-carrier/CarrierCallins", {
                maxWidth: 1100, width: window.innerWidth * .55, height: 325,
                backgroundColor: "defaultBackground",
                borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary"
            }) as CarrierCallins;

            this.setupCarrierCallins(movementId, orderId, pickupLoc, deliveryLoc, commodity, layout, labelCallinStatus);
            this.carrierCallinDialog = CommonDialogs.createDialog(layout, { title: "Add Callins", okVisible: false });
            this.carrierCallinDialog.show();
        });
    }

    public setupCarrierCallins(movementId: string, orderId: string, pickupLoc: string, deliveryLoc: string,
        commodity: string, layout: CarrierCallins, labelCallinStatus: Label) {

        layout.addLayoutDataLoadListener(event => {

            layout.labelOrderNumberData.caption = orderId;
            layout.labelPickupData.caption = pickupLoc;
            layout.labelDeliveryData.caption = deliveryLoc;
            layout.labelCommodityData.caption = commodity;
            this.tabCallins.select();
            layout.mainDataSource.mode = DataSourceMode.ADD;

            layout.citystate1.addBlurListener(event => {
                layout.mainDataSource.activeRow.set("timezone_id", "xx");
            });

            layout.mainDataSource.addBeforeExecutionListener((event: DataSourceExecutionEvent) => {
                if (event.getAction() === DataSourceAction.ADD) {
                    event.dataSource.activeRow?.set("movement_id", movementId);
                    event.dataSource.activeRow?.set("order_id", orderId);
                    event.dataSource.activeRow?.set("initiated_type", "C");
                }
            });
            layout.buttonCancel.addClickListener(event => {
                this.carrierCallinDialog.close();
            });
            layout.buttonSave.addClickListener(event => {
                layout.mainDataSource.addAfterExecutionListener(() => {
                    this.tabCallins.dataSource.search({ movement_id: movementId, order_id: orderId })
                    layout.mainDataSource.mode = DataSourceMode.ADD;
                    this.labelNoCallins.caption = "";
                    this.setlabelCallinStatus(labelCallinStatus);
                    this.carrierCallinDialog.close();
                });
            });
        });
    }

    carrierImagesDetailTableOnRowDisplay(event: TableRowDisplayEvent) {
        const attachment = event.getTableRow().findComponentById("attachmentAttachment") as Attachment;
        if (attachment["_printLabel"]) {
            attachment["_printLabel"].align = HorizontalAlignment.LEFT;
            attachment.minHeight = attachment["_printLabel"].height;
        }
    }
}

