import { DataSource } from "@mcleod/components";
import { CityUtil, getUnauthSettings } from "@mcleod/core";
import { LtlQuote } from "./LtlQuote";
import { AutogenLayoutCarrierSelectionOverview } from "./autogen/AutogenLayoutCarrierSelectionOverview";

export class CarrierSelectionOverview extends AutogenLayoutCarrierSelectionOverview {
    private _ltlQuote: LtlQuote;

    public get ltlQuote(): LtlQuote {
        return this._ltlQuote;
    }

    public set ltlQuote(value: LtlQuote) {
        if (this._ltlQuote == null) {
            this._ltlQuote = value;
            this.setupStopsPanel(this.ltlQuote.sourceQuoteStops);
        }
    }

    override onLoad() {
        this.labelWelcomeMsg.caption = getUnauthSettings().company_settings["order_welcome_message"];
    }

    setupStopsPanel(sourceQuoteStops: DataSource) {
        const shipperRow = sourceQuoteStops.data[0];
        this.labelPickupAddress.caption = shipperRow.get("location_name");
        this.labelPickupAddress.caption = shipperRow.get("address1");
        this.labelPickupCityState.caption = CityUtil.formatCityStateZip(shipperRow.get("city_name"), shipperRow.get("state_id"), shipperRow.get("zip_code"))

        //   labelPickupAddress: Label;
        // labelPickupCityState: Label;
        // labelPickupDelivery: Label;
        // labelPickupName: Label;
        const consigneeRow = sourceQuoteStops.data[1];
    }
}
