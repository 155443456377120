import { Button, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutAccept extends Layout {
    buttonConfirmAccept: Button;
    labelAccept: Label;
    labelEmailOfferConfirmation: Label;
    labelSuccess: Label;
    labelThisLoadIsNoLongerAvailable: Label;
    panelAvailable: Panel;
    panelNotAvailable: Panel;
    panelSuccess: Panel;
    sourceCarrierAvailableOrders: DataSource;
    textboxOrderId: Textbox;
}
