import { DataSourceMode, Toast } from "@mcleod/components";
import { Api, Navigation, StringUtil, UrlUtil, getLogger } from "@mcleod/core";
import { ErrorHandlerFunction } from "@mcleod/core/src/ErrorHandler";
import { AutogenLayoutDecline } from "./autogen/AutogenLayoutDecline";

const log = getLogger("portal-carrier/Decline");

export class Decline extends AutogenLayoutDecline {

    private _declineReason: String;

    override onLoad() {
        const props = UrlUtil.getPropsFromUrl(document.location.search);
        if (!StringUtil.isEmptyString(props.token)) {
            this._declineReason = props.reason;
            if (this._declineReason == 'E' || this._declineReason == 'L') {
                const captionReason = this._declineReason == 'E' ? "No Equipment" : "Not My Lane";
                this.buttonConfirmDecline.caption = this.buttonConfirmDecline.caption + ": " + captionReason;
            }
            this.mainDataSource.search({ type: "pending", token: props.token }, this._errorHandler).then(response => {
                if (response?.modelRows?.length > 0) {
                    this.panelAvailable.visible = true;
                }
                else {
                    this.panelNotAvailable.visible = true;
                    this.panelAvailable.visible = false;
                }
            });
        } else {
            this.panelNotAvailable.visible = true;
            this.panelAvailable.visible = false;
        }

        this.mainDataSource.mode = DataSourceMode.NONE;
    }

    confirmDecline() {

        const props = UrlUtil.getPropsFromUrl(document.location.search);
        if (!StringUtil.isEmptyString(props.token)) {
            Api.post("portal/carrier/carrier-decline-offer", { token: props.token, decline_reason: this._declineReason }).then(response => {
                if (!StringUtil.isEmptyString(response.data[0].success)) {
                    Toast.showSuccessToast(response.data[0].success);
                    this.panelAvailable.visible = false;
                    this.panelSuccess.visible = true;
                }
            }).catch(err => {
                log.error(err);
            });
        }
    }

    _errorHandler: ErrorHandlerFunction = (error) => {
        Navigation.navigateTo("");
    }

}
