import { Button, CityState, DataSource, Label, Layout, Panel, SaveButton, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutCarrierCallins extends Layout {
    buttonCancel: Button;
    buttonSave: SaveButton;
    citystate1: CityState;
    labelCommodity: Label;
    labelCommodityData: Label;
    labelDelivery: Label;
    labelDeliveryData: Label;
    labelOrderNumber: Label;
    labelOrderNumberData: Label;
    labelPickup: Label;
    labelPickupData: Label;
    label_: Label;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    sourceCarrierCallins: DataSource;
    textboxCallDateTime: Textbox;
    textboxRemark: Textbox;
    textboxSetpointTemp: Textbox;
    textboxTemperature: Textbox;
}
