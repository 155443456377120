import { ClickEvent, Panel } from "@mcleod/components";
import { Navigation } from "@mcleod/core";
import { userCanEnterFtlOrder, userCanEnterFtlQuote, userCanEnterLtlOrder, userCanEnterLtlQuote } from "./OrderValidation";
import { AutogenLayoutOrderQuoteButtonPanel } from "./autogen/AutogenLayoutOrderQuoteButtonPanel";

export class OrderQuoteButtonPanel extends AutogenLayoutOrderQuoteButtonPanel {

    override async onLoad() {
        this.displayButtonPanel(this.panelAddOrder, userCanEnterFtlOrder());
        this.displayButtonPanel(this.panelAddFTLQuote, userCanEnterFtlQuote())
        this.displayButtonPanel(this.panelAddLtlOrder, await userCanEnterLtlOrder());
        this.displayButtonPanel(this.panelAddQuote, userCanEnterLtlQuote());
        for (let i = 1; i < this.components.length; i++)
            this.components[i].setProps({ borderLeftColor: "subtle.light", borderLeftWidth: 1 });
    }

    private displayButtonPanel(buttonPanel: Panel, displayPanel: boolean) {
        if (displayPanel)
            buttonPanel.visible = true;
        else
            this.remove(buttonPanel);
    }

    addQuoteOnClick(event: ClickEvent) {
        Navigation.navigateTo("/portal-customer/ltl/LtlQuote");
    }

    addFTLQuoteOnClick(event: ClickEvent) {
        Navigation.navigateTo("/portal-customer/FtlQuote");
    }

    addLtlOrderOnClick(event: ClickEvent) {
        Navigation.navigateTo("/portal-customer/ltl/LtlOrder");
    }

    addOrderOnClick(event: ClickEvent) {
        Navigation.navigateTo("/portal-customer/FtlOrder");
    }
}
