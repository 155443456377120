import { Layout, Tab, Tabset } from "@mcleod/components";
import { DriverChoice } from "../DriverChoice";
import { DriverChoiceManualSearch } from "../DriverChoiceManualSearch";

export abstract class AutogenLayoutDriverPortal extends Layout {
    layout1: DriverChoice;
    layoutManualSearch: DriverChoiceManualSearch;
    tabManualSearch: Tab;
    tabMyMatchingLoads: Tab;
    tabset1: Tabset;
}
