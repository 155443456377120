import { Button, Checkbox, DataSource, HorizontalSpacer, Label, Layout, Panel, PortalLocation, Switch, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutAddStop extends Layout {
    buttonDelete: Button;
    checkboxApptRequired: Checkbox;
    horizontalspacer1: HorizontalSpacer;
    labelStopType: Label;
    locationStop: PortalLocation;
    panel1: Panel;
    panel5: Panel;
    panelDateTimes: Panel;
    panelDates: Panel;
    panelLoc: Panel;
    panelStopRow: Panel;
    sourceStop: DataSource;
    switchStopType: Switch;
    textPickupDate: Textbox;
    textboxContactName: Textbox;
    textboxEarlyPickup: Textbox;
    textboxLatePickup: Textbox;
    textboxPhone: Textbox;
}
