import { DataSource, Label, Layout, Tab, Table, Tabset } from "@mcleod/components";

export abstract class AutogenLayoutFactoringOrdersReceivedDetails extends Layout {
    factoringComments: Table;
    factoringImagesDetailTable: Table;
    labelNoComments: Label;
    labelNoImages: Label;
    sourceFactoringImages: DataSource;
    sourcePaymentsReceivedComments: DataSource;
    tabComments: Tab;
    tabImages: Tab;
    tabsetOrderDetails: Tabset;
}
