import { Checkbox, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";
import { FtlQuoteAndOrderOverview } from "../FtlQuoteAndOrderOverview";
import { FtlQuoteStopDetails } from "../FtlQuoteStopDetails";

export abstract class AutogenLayoutFtlQuoteDetails extends Layout {
    checkAgree: Checkbox;
    labelAddlDetails: Label;
    labelOrderConfirmation: Label;
    labelOrderSubmitMsg: Label;
    labelTerms: Label;
    labelTermsTitle: Label;
    layoutOverview: FtlQuoteAndOrderOverview;
    layoutQuoteStops: FtlQuoteStopDetails;
    panelAddlDetails: Panel;
    panelConfirm: Panel;
    panelDetailsLeft: Panel;
    panelDetailsRight: Panel;
    panelTerms: Panel;
    sourceFtlQuoteOrder: DataSource;
    textboxBlnum: Textbox;
    textboxCommodityId: Textbox;
    textboxConsigneeRefno: Textbox;
    textboxEquipmentTypeId: Textbox;
    textboxPlanningComment: Textbox;
    textboxShipperPo: Textbox;
    textboxShipperRef: Textbox;
    textboxWeight: Textbox;
}
