import { DataSource, Label, Layout, Panel, Tab, Table, Tabset } from "@mcleod/components";
import { AdditionalDetailsFTL } from "../AdditionalDetailsFTL";
import { OrderImages } from "../OrderImages";
import { OrderMapView } from "../OrderMapView";
import { TableStops } from "../TableStops";
import { AdditionalDetailsLTL } from "../ltl/AdditionalDetailsLTL";

export abstract class AutogenLayoutOrderDetails extends Layout {
    freightItems: Label;
    labelConsigneeETA: Label;
    labelEtaDateTime: Label;
    labelLabel2: Label;
    labelLabel4: Label;
    labelLabel5: Label;
    labelLabel6: Label;
    labelMapConsigneeETA: Label;
    labelMapEtaDateTime: Label;
    labelPieces: Label;
    labelSpots: Label;
    labelTotalCharges: Label;
    labelWeight: Label;
    layout1: OrderMapView;
    layoutFTLAddlDetails: AdditionalDetailsFTL;
    layoutImageTab: OrderImages;
    layoutLTLAddlDetails: AdditionalDetailsLTL;
    layoutStops: TableStops;
    panel1: Panel;
    panel2: Panel;
    panelAddlDetails: Panel;
    panelEta: Panel;
    panelLtlDetails: Panel;
    panelLtlDetailsMain: Panel;
    panelMain2: Panel;
    panelMainStops: Panel;
    panelMapEta: Panel;
    panelRates: Panel;
    paneltabImagesAddlDetails: Panel;
    paneltabLTLDetailsAddlDetails: Panel;
    paneltabMapViewAddlDetails: Panel;
    paneltabStopsAddlDetails: Panel;
    ratings: Label;
    sourceCustomerOrderDetail: DataSource;
    sourceFreightGroupItem: DataSource;
    sourceLtlOrderDetails: DataSource;
    sourceLtlRatings: DataSource;
    tabImages: Tab;
    tabLTLDetails: Tab;
    tabMapView: Tab;
    tabStops: Tab;
    tableFreightGroupItems: Table;
    tableLTLDetails: Table;
    tabset1: Tabset;
}
