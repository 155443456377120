import { Button, DataSource, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";
import { BillOfLadingPanel } from "../BillOfLadingPanel";

export abstract class AutogenLayoutQuoteAndOrderOverview extends Layout {
    buttonQuoteSheet: Button;
    labelCarrier: Label;
    labelChargeDetails: Label;
    labelDetailTotal: Label;
    labelFreightSummary: Label;
    labelLabel1: Label;
    labelLabel3: Label;
    labelQuoteId: Label;
    labelTitleQuoteNumber: Label;
    labelTotal: Label;
    labelTotalCharge: Label;
    lableTotal: Label;
    layoutBOL: BillOfLadingPanel;
    panel4: Panel;
    panel8: Panel;
    panelCarrier: Panel;
    panelFreight: Panel;
    panelImage: Panel;
    panelMain: Panel;
    panelOrderNumber: Panel;
    panelQuoteNumber: Panel;
    panelTotal: Panel;
    sourceQuoteFreightGroupItem: DataSource;
    sourceRates: DataSource;
    sourceRevenueDetail: DataSource;
    tableRevDetails: Table;
    textboxEstDeliveryDisplayValue: Textbox;
    textboxFreightClass: Textbox;
    textboxWeight: Textbox;
}
