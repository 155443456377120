import { DataDisplayEvent, Label } from "@mcleod/components";
import { Alignment } from "@mcleod/core";
import { AutogenLayoutLtlCommodityLookup } from "./autogen/AutogenLayoutLtlCommodityLookup";
export class LtlCommodityLookup extends AutogenLayoutLtlCommodityLookup {

    labelHazmatOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        label.caption = null;
        if (event.rowData?.get("is_hazmat") === "Y") {
            label.imageName = "warning";
            label.imageColor = "red";
            label.imageAlign = Alignment.LEFT;
        }
    }
}
