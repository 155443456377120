import { Button, ClickEvent, Image, ImageType, Panel, TableRow, TableRowDisplayEvent, TableRowExpansionEvent } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { CarrierSelectionExpand } from "./CarrierSelectionExpand";
import { AutogenLayoutCarrierSelection } from "./autogen/AutogenLayoutCarrierSelection";
export class CarrierSelection extends AutogenLayoutCarrierSelection {
    private _carrierRates: any;
    public quoteId: string;
    public selectRateCallback: (modelRow: ModelRow<any>) => void;
    private _selectedRate: ModelRow<any>;

    public get selectedRate(): ModelRow<any> {
        return this._selectedRate;
    }

    private set selectedRate(value: ModelRow<any>) {
        if (this._selectedRate != null)
            delete this._selectedRate.data["selected"];
        if (value != null)
            value.set("selected", true);
        this._selectedRate = value;
        this.tableRates.rows.forEach(row => this.syncButtonSelectCarrier(row));
    }

    private syncButtonSelectCarrier(tableRow: TableRow) {
        const button = tableRow?.findComponentById("buttonSelectCarrier") as Button;
        const selected = tableRow.data.getBoolean("selected", false);
        if (button != null) {
            button.caption = selected ? "Carrier Selected" : "Select and Continue";
            button.color = selected ? "success" : "primary";
            button.imageName = selected ? "circleCheckEmpty" : null;
        }
    }

    public get carrierRates(): any {
        return this._carrierRates;
    }

    public set carrierRates(value: any) {
        if (this.sourceRates.data?.length == 0) {
            this.sourceRates.orderBy = [{ field: "revenue_details_total", sort: "asc" }];
            this._carrierRates = value;

            value?.forEach(data => {
                const row = new ModelRow(this.sourceRates.url, false, { ...data });
                this.sourceRates.data.push(row);
                this.sourceRates.displayDataInBoundComponents();
            });
        }
    }

    tableRatesOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        if (!tableRow.data?.isNull("image_bytes")) {
            const panel = tableRow.findComponentById("panelImage") as Panel;
            const img = new Image({
                id: "carrierImg", imageType: ImageType.IMG,
                preserveAspectRatio: true, height: 50, width: 100
            });

            img.imageBytes = tableRow.data?.get("image_bytes");
            panel.add(img);
        }

        if (tableRow.data.getBoolean("selected", false))
            this.syncButtonSelectCarrier(tableRow);
    }

    /** This is an event handler for the onClick event of buttonSelectCarrier.  */
    buttonSelectCarrierOnClick(event: ClickEvent) {
        const button = event.target as Button;
        const tableRow = TableRow.getContainingTableRow(button);
        const data = tableRow.data;

        this.selectedRate = tableRow.data;

        if (this.selectRateCallback != null)
            this.selectRateCallback(data);
    }

    /** This is an event handler for the onRowExpand event of tableRates.  */
    tableRatesOnRowExpand(event: TableRowExpansionEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const revDetails = tableRow.data?.get("revenue_details");
        if (event.isExpanding && revDetails != null) {
            const expandPanel = event.expandComponentParent as Panel;
            const layoutExpand = expandPanel.findComponentById("layoutExpand") as CarrierSelectionExpand;
            layoutExpand.addLayoutLoadListener(() => {
                layoutExpand.sourceRevenueDetail.clear();
                revDetails?.forEach(data => {
                    const row = new ModelRow(layoutExpand.sourceRevenueDetail.url, false, { ...data });
                    layoutExpand.sourceRevenueDetail.data.push(row);
                    layoutExpand.sourceRevenueDetail.displayDataInBoundComponents();
                });
            });
        }
    }
}
