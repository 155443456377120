import { AutogenLayoutLtlQuoteStopDetails } from "./autogen/AutogenLayoutLtlQuoteStopDetails";

export class LtlQuoteStopDetails extends AutogenLayoutLtlQuoteStopDetails {

    override onLoad() {
        this.layoutStopTable.tableStops.dataSource = this.mainDataSource;
    }

    public get hasValidPickupTimes(): boolean {
        return this.layoutStopTable.hasValidPickupTimes;
    }

    async validatePickupTimesBeforePost() {
        await this.layoutStopTable.validatePickupTimes(true);
    }
}
