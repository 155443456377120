import { Layout, Switch, Tab, Tabset } from "@mcleod/components";
import { AdvancedSearch } from "../AdvancedSearch";
import { OrderQuoteButtonPanel } from "../OrderQuoteButtonPanel";
import { TableCustomerOrders } from "../TableCustomerOrders";
import { TableCustomerQuotes } from "../TableCustomerQuotes";

export abstract class AutogenLayoutCustomerPortal extends Layout {
    layoutAdvanceSearch: AdvancedSearch;
    layoutOrderQuoteButtonPanel: OrderQuoteButtonPanel;
    layoutQuoteList: TableCustomerQuotes;
    switchListMapView: Switch;
    tabAdvancedSearch: Tab;
    tabAllOrders: Tab;
    tabDelivered: Tab;
    tabInTransit: Tab;
    tabQuotes: Tab;
    tabScheduled: Tab;
    tableAllOrders: TableCustomerOrders;
    tableDelivered: TableCustomerOrders;
    tableInTransit: TableCustomerOrders;
    tableScheduled: TableCustomerOrders;
    tabset: Tabset;
}
