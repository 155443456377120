import { Button, DataSource, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCounter extends Layout {
    buttonConfirmCounter: Button;
    panelAvailable: Panel;
    panelNotAvailable: Panel;
    panelSuccess: Panel;
    sourceCarrierAvailableOrders: DataSource;
    textboxCounterAmount: Textbox;
}
