import { ChangeEvent, Textbox } from "@mcleod/components";
import { AutogenLayoutDriverChoicePreferences } from "./autogen/AutogenLayoutDriverChoicePreferences";

export class DriverChoicePreferences extends AutogenLayoutDriverChoicePreferences {
    onChangeTopPriority(event: ChangeEvent) {
        const textbox = event.target as Textbox;
        if (textbox.selectedItem?.value != null) {
            if ("ED" == textbox.selectedItem.value) {
                this.labelPriorityOneLG.caption = "less than";
                this.labelPriorityOneMD.caption = "miles";
                this.textboxChoicePriority1Value.maxValue = null;
            }
            else if ("MR" == textbox.selectedItem.value) {
                this.labelPriorityOneLG.caption = "greater than";
                this.labelPriorityOneMD.caption = "dollars";
                this.textboxChoicePriority1Value.maxValue = 9999.99;
            }
        }
    }

    onChangeNextPriority(event: ChangeEvent) {
        const textbox = event.target as Textbox;
        if (textbox.selectedItem?.value != null) {
            if ("ED" == textbox.selectedItem.value) {
                this.labelPriorityTwoLG.caption = "less than";
                this.labelPriorityTwoMD.caption = "miles";
                this.textboxChoicePriority2Value.maxValue = null;
            }
            else if ("MR" == textbox.selectedItem.value) {
                this.labelPriorityTwoLG.caption = "greater than";
                this.labelPriorityTwoMD.caption = "dollars";
                this.textboxChoicePriority2Value.maxValue = 9999.99;
            }
        }
        else {
            this.textboxChoicePriority2Value.clear();
        }
    }
}
