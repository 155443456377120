import { Button, CityState, DataSource, HorizontalSpacer, Label, Layout, Panel, Switch, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutTableCarrierLoadboard extends Layout {
    buttonClearForm: Button;
    buttonFilterExpand: Button;
    buttonSearch: Button;
    citystateDeliveryLoc: CityState;
    citystatePickupLoc: CityState;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    horizontalspacer3: HorizontalSpacer;
    labelFilters: Label;
    panel15: Panel;
    panel16: Panel;
    panelDeliveryCityState: Panel;
    panelDeliveryState: Panel;
    panelFilter: Panel;
    panelFilterHeader: Panel;
    panelPickupCityState: Panel;
    panelPickupState: Panel;
    sourceCarrierLoadboardOrder: DataSource;
    switchDate: Switch;
    switchDeliveryLocationType: Switch;
    switchPickupLocationType: Switch;
    tableLoadboard: Table;
    textboxDeliveryRadius: Textbox;
    textboxDeliveryState: Textbox;
    textboxOrdersequipmentTypeId: Textbox;
    textboxPickupDateStart: Textbox;
    textboxPickupRadius: Textbox;
    textboxPickupState: Textbox;
    textboxShipDateTo: Textbox;
}
