import { AutogenLayoutFtlQuoteStopDetails } from "./autogen/AutogenLayoutFtlQuoteStopDetails";

export class FtlQuoteStopDetails extends AutogenLayoutFtlQuoteStopDetails {

    override onLoad() {
        this.layoutStopTable.tableStops.dataSource = this.mainDataSource;
    }

    public get hasValidPickupTimes(): boolean {
        return this.layoutStopTable.hasValidPickupTimes;
    }

    async validatePickupTimesBeforePost() {
        for (let i = 0; i < this.sourceQuoteStops.data.length; i++) {
            await this.layoutStopTable.validatePickupTimes(true, i);
            if (this.layoutStopTable.hasValidPickupTimes === false)
                break;
        }
    }
}
