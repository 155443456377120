import { BlurEvent, ChangeEvent, CityState, ClickEvent, Component, DataDisplayEvent, DataSourceMode, Label, TableRowDisplayEvent, Textbox, ValidationResult } from "@mcleod/components";
import { getAuthSettings, getUnauthSettings } from "@mcleod/core";
import { getLogger } from "@mcleod/core/src/Logger";
import { createTableEmptyComponent } from "./CarrierPortal";
import { AutogenLayoutTableCarrierLoadboard } from "./autogen/AutogenLayoutTableCarrierLoadboard";

const log = getLogger("portal-carrier/TableCarrierLoadboard");

export class TableCarrierLoadboard extends AutogenLayoutTableCarrierLoadboard {

    override onLoad() {
        let captionToUse = "No loads available for display";
        const wwwControlSettings = getUnauthSettings().company_settings;
        const loadboardShowB4Search = wwwControlSettings["loadboard_show_b4_search"];
        if (!loadboardShowB4Search) {
            captionToUse = "Enter search criteria and click search.";
        }
        this.tableLoadboard.emptyComponent = createTableEmptyComponent(captionToUse);
        this.panelFilter.forEveryChildComponent((component: Component) => {
            if (component instanceof Textbox) {
                //textbox validator does not set red border for datasource != add
                component.addBlurListener((event: BlurEvent) => {
                    const tb = event.target as Textbox;
                    if (tb.validationWarning != null) {
                        if (tb.borderWidth == null)
                            tb.borderWidth = 1;
                        tb.borderColor = "error";
                    }
                });
            }
        });
    }
    /** This is an event handler for the onChange event of switchDate.  */
    switchDateOnChange(event) {
        if (event.newValue) {
            this.textboxShipDateTo.visible = true;
        } else {
            this.textboxShipDateTo.visible = false;
            this.textboxShipDateTo.text = "";
        }
    }
    /** This is an event handler for the onRowDisplay event of tableLoadboard.  */
    tableLoadboardOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const labelEmail = tableRow?.findComponentById("labelAvailableEmail") as Label;
        if (labelEmail && labelEmail.visible) {
            const opsUserEmail = tableRow.data.get("contact_email");
            const emailToUse = (opsUserEmail != null) ? opsUserEmail : getAuthSettings().support_email_address;
            labelEmail.link = "mailto:" + emailToUse + "?subject=Question about Available Order "
                + tableRow.data.get("order_id") + " " + tableRow.data.get("origin_loc") + " to " + tableRow.data.get("destination_loc");
        }
    }

    /** This is an event handler for the onClick event of buttonClearForm.  */
    buttonClearFormOnClick(event: ClickEvent) {
        this.panelFilter.forEveryChildComponent((component: Component) => {
            this.clearFields(component);
        });
    }

    labelDistanceOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        if (label.getDataValue() != null)
            label.caption += " MI";
    }

    /** This is an event handler for the onChange event of switchPickupLocationType.  */
    switchPickupLocationTypeOnChange(event: ChangeEvent) {
        if (event.newValue == true) {
            this.textboxPickupState.clear();
            this.panelPickupState.visible = false;
            this.panelPickupCityState.visible = true;
            this.textboxPickupRadius.required = true;
            this.citystatePickupLoc.required = true;
        }
        else {
            this.panelPickupCityState.forEveryChildComponent((component: Component) => {
                this.clearFields(component);
            });
            this.panelPickupState.visible = true;
            this.panelPickupCityState.visible = false;
            this.textboxPickupRadius.required = false;
            this.citystatePickupLoc.required = false;
        }
    }

    /** This is an event handler for the onChange event of switchDeliveryLocationType.  */
    switchDeliveryLocationTypeOnChange(event: ChangeEvent) {
        if (event.newValue == true) {
            this.textboxDeliveryState.clear();
            this.panelDeliveryState.visible = false;
            this.panelDeliveryCityState.visible = true;
            this.textboxDeliveryRadius.required = true
            this.citystateDeliveryLoc.required = true;
        }
        else {
            this.panelDeliveryState.visible = true;
            this.panelDeliveryCityState.visible = false;
            this.textboxDeliveryRadius.required = false;
            this.citystateDeliveryLoc.required = false;
            this.panelDeliveryCityState.forEveryChildComponent((component: Component) => {
                this.clearFields(component);
            });
        }
    }

    /** This is an event handler for the onClick event of buttonSearch.  */
    buttonSearchOnClick(event: ClickEvent) {
        const validationResult: ValidationResult[] = this.panelFilter.validate(true, true);
        if (validationResult == null || validationResult.filter(r => !r.isValid).length == 0) {
            this.buttonSearch.busy = true;
            this.mainDataSource.search(this.mainDataSource.getDataboundValues(DataSourceMode.SEARCH, null, true)).then(() => {
                this.buttonSearch.busy = false;
            });
        }
        else {
            for (const result of validationResult) {
                if (result.component instanceof Textbox) {
                    if (result.component.borderWidth == null)
                        result.component.borderWidth = 1;
                    result.component.borderColor = "error";
                }
                else if (result.component instanceof CityState) {
                    if (result.component.textCombined.borderWidth == null)
                        result.component.textCombined.borderWidth = 1;
                    result.component.textCombined.borderColor = "error";
                }
            }
        }
    }

    clearFields(component: Component) {
        if (component instanceof Textbox) {
            component.clear();
            component.validationWarning = null;
        }
        else if (component instanceof CityState) {
            component.textCombined.clear();
            component.textCombined.validationWarning = null;
            component.resetValidation();
        }
    }

    buttonFilterExpandOnClick(event: ClickEvent) {
        const transitionProps = { speed: 100, paddingTop: 0 };
        if (this.panelFilter.style.height == "0px")
            this.panelFilter.expand(transitionProps).then(() => {
                this.setSearchBordersExpanded();
            });
        else
            this.panelFilter.collapse(transitionProps).then(() => {
                this.setSearchBordersCollapsed();
            });
    }

    setSearchBordersCollapsed() {
        this.buttonFilterExpand.imageRotation = 90;
    }

    setSearchBordersExpanded() {
        this.buttonFilterExpand.imageRotation = 0;
    }
}
