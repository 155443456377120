import { DataSource, Label, Layout, Panel, Table } from "@mcleod/components";

export abstract class AutogenLayoutHandlingRequirements extends Layout {
    labelNoneSelected: Label;
    labelTitle: Label;
    panel1: Panel;
    sourceHandlingRequirements: DataSource;
    sourceLtlQuoteHdrXFgp: DataSource;
    tableHdrs: Table;
}
