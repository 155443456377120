import { Label, TableRowDisplayEvent } from "@mcleod/components";
import { AutogenLayoutOrderConfirmationStops } from "./autogen/AutogenLayoutOrderConfirmationStops";

export class OrderConfirmationStops extends AutogenLayoutOrderConfirmationStops {
    private _isLtl = false;

    public get isLtl() {
        return this._isLtl;
    }
    public set isLtl(value) {
        this._isLtl = value;
        if (this.isLtl) {
            this.lablePickupsDeliveries.caption = "Pickup and Delivery";
        }
    }

    tableStopsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const data = tableRow.data;
        (tableRow.findComponentById("labelSequence") as Label).caption = (tableRow.index + 1).toString();
        tableRow.findComponentById("labelApptRequired").visible = data.getBoolean("appt_required");
        const labelStopType = tableRow.findComponentById("labelStopType") as Label;
        if (this.isLtl) {
            labelStopType.field = "stop_type_descr";
            labelStopType.displayData(data, null, 0);
        } else {
            labelStopType.caption = data.get("stop_type") == "PU" ? "Pickup" : "Delivery";
        }
    }
}
