import { DataSourceExecutionEvent, Table, TableRowMode } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { AutogenLayoutPaymentsReceivedDetails } from "./autogen/AutogenLayoutPaymentsReceivedDetails";

export class PaymentsReceivedDetails extends AutogenLayoutPaymentsReceivedDetails {


    /** This is an event handler for the afterExecution event of sourceFactoringPaymentDetails.  */
    sourceFactoringPaymentDetailsAfterExecution(event: DataSourceExecutionEvent) {
        const payDetailTable = this.tablePaymentDetails as Table;
        const data = event.dataSource.data;
        const deductions: [] = data[0]?.get("deductions");
        const netPay = data[0]?.get("net_pay");
        const linehaul = data[0]?.get("linehaul");
        payDetailTable.removeRow(0);//remove blank row. seems there should be a better way I'm missing
        const modelRowLineHaul = new ModelRow(this.sourceFactoringPaymentDetails.url, false, { short_desc: "Linehaul", amount: linehaul });
        payDetailTable.addRow(modelRowLineHaul, { mode: TableRowMode.NONE }, { display: true, addToData: payDetailTable.dataSource.isAddingOrUpdating() });
        if (deductions != null && deductions.length > 0) {
            deductions.forEach(deduction => {
                const modelRowLineDeduction = new ModelRow(this.sourceFactoringPaymentDetails.url, false, { short_desc: deduction["short_desc"], amount: deduction["amount"] });
                payDetailTable.addRow(modelRowLineDeduction, { mode: TableRowMode.NONE }, { display: true, addToData: payDetailTable.dataSource.isAddingOrUpdating() });
            });
        }
        const modelRowNet = new ModelRow(this.sourceFactoringPaymentDetails.url, false, { short_desc: "Net Pay", amount: netPay });
        payDetailTable.addRow(modelRowNet, { mode: TableRowMode.NONE, color: "primary" }, { display: true, addToData: payDetailTable.dataSource.isAddingOrUpdating() });
    }
}
