import { DataDisplayEvent, Label } from "@mcleod/components";
import { Alignment } from "@mcleod/core";
import { AutogenLayoutFtlCommodityLookup } from "./autogen/AutogenLayoutFtlCommodityLookup";

export class FtlCommodityLookup extends AutogenLayoutFtlCommodityLookup {
    labelHazmatOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        label.caption = null;
        if (event.rowData?.get("is_hazmat") === "Y") {
            label.imageName = "warning";
            label.imageColor = "red";
            label.imageAlign = Alignment.LEFT;
        }
    }
}
