import { CommonDialogs } from "@mcleod/common";
import { Api, UrlUtil } from "@mcleod/core";
import { AutogenLayoutOfferUnsubscribe } from "./autogen/AutogenLayoutOfferUnsubscribe";

export class OfferUnsubscribe extends AutogenLayoutOfferUnsubscribe {
    override async onLoad() {
        const props = UrlUtil.getPropsFromUrl(document.location.search);
        this.labelResponse.busy = true;
        Api.post("portal/carrier/offer_unsubscribe", {
            company_id: props.STCID,
            offer_id: props.offerId
        }).then(() => {
            this.labelResponse.caption = "You have been successfully unsubscribed.";
        }).catch(err => {
            this.labelResponse.caption = "Error unsubscribing";
            CommonDialogs.showError(err);
        }).finally(() => this.labelResponse.busy = false);
    }
}
