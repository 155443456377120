import { DataDisplayEvent, DataSourceExecutionEvent, Label, Panel } from "@mcleod/components";
import { AutogenLayoutTableStops } from "./autogen/AutogenLayoutTableStops";

export class TableStops extends AutogenLayoutTableStops {

    private stops: [];

    /** This is an event handler for the onDataDisplay event of labelStopNumber.  */
    labelStopNumberOnDataDisplay(event: DataDisplayEvent) {
        const status = event.rowData.data["status"];
        const statusLabel = (event.target as Label)
        if (status != null && status == "D") {
            statusLabel.caption = null;
            statusLabel.padding = 0;
            statusLabel.imageName = "circleCheck";
            statusLabel.imageColor = "primary";
            statusLabel.imageWidth = 27;
            statusLabel.borderWidth = 0;

        }
        else if (event.rowData.data["actual_arrival"] != null && event.rowData.data["actual_departure"] == null) {
            statusLabel.backgroundColor = "primary";
            statusLabel.color = "primary.reverse"
        }
    }

    /** This is an event handler for the afterExecution event of sourceOrderStops.  */
    sourceOrderStopsAfterExecution(event: DataSourceExecutionEvent) {
        this.stops = event.dataSource.activeRow?.get("stops");
    }

    /** This is an event handler for the onDataDisplay event of textboxSchedArriveEarly.  */
    textboxSchedArriveEarlyOnDataDisplay(event: DataDisplayEvent) {
        const late = event.rowData.data["sched_arrive_late"];
        if (late != null) {
            (event.target as Label).caption = "Scheduled to arrive between"

        }
    }

    /** This is an event handler for the onDataDisplay event of panelLine.  */
    panelLineOnDataDisplay(event: DataDisplayEvent) {
        if (this.stops != null && event.rowData.data["order_sequence"] == this.stops.length) {
            (event.target as Panel).visible = false;
        }
    }
}
