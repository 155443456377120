import { BlurEvent, Component, Layout, Textbox, ValidationResult } from "@mcleod/components";
import { TableCustomerOrders } from "./TableCustomerOrders";
import { AutogenLayoutAdvancedSearch } from "./autogen/AutogenLayoutAdvancedSearch";
export class AdvancedSearch extends AutogenLayoutAdvancedSearch {
    override async onLoad(): Promise<void> {
        this.switchDate.checked = true;
        this.switchDate.checked = false;
        this.textboxStatus.selectedItem = this.textboxStatus.items[0];
        this.textboxShipDateTo.visible = false;
        this.location.webLocationsOnly = true;
        this.location.textCombined.manualAddLayout = null;
        this.panelSearch.forEveryChildComponent((component: Component) => {
            if (component instanceof Textbox) {
                //textbox validator does not set red border for datasource != add
                component.addBlurListener((event: BlurEvent) => {
                    const tb = event.target as Textbox;
                    if (tb.validationWarning != null) {
                        if (tb.borderWidth == null)
                            tb.borderWidth = 1;
                        tb.borderColor = "error";
                    }
                    const validationResult: ValidationResult[] = this.panelSearch.validate(true, true);
                    if (validationResult == null || validationResult.filter(r => !r.isValid).length == 0) {
                        this.searchbuttonSearch.enabled = true;
                    } else {
                        this.searchbuttonSearch.enabled = false;
                    }
                });
            }
        });
        this.switchDate.addChangeListener(event => {
            if (event.newValue) {
                this.textboxShipDateTo.visible = true;
            } else {
                this.textboxShipDateTo.visible = false;
                this.textboxShipDateTo.text = "";
            }
        });
        const layout = Layout.getLayout("portal-customer/TableCustomerOrders", { id: "layoutTableCustomerOrders" }) as TableCustomerOrders;
        return new Promise((resolve) => {
            layout.addLayoutLoadListener(() => {
                this.replaceMainDatasource(layout.mainDataSource);
                this.add(layout);
                this.panelSearch.forEveryChildComponent(comp => {
                    if (comp.dataSource != null)
                        comp.dataSource = layout.mainDataSource;
                })
                return resolve();
            });
        });
    }
}
