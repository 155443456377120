import { DataSource, Label, Layout, Panel } from "@mcleod/components";
import { BillOfLadingPanel } from "../ltl/BillOfLadingPanel";

export abstract class AutogenLayoutFtlQuoteAndOrderOverview extends Layout {
    distanceLabel: Label;
    labelBillDistance: Label;
    labelFreight: Label;
    labelFreightCharge: Label;
    labelFreightSummary: Label;
    labelOther: Label;
    labelOtherchargetotal: Label;
    labelQuoteId: Label;
    labelTitleQuoteNumber: Label;
    labelTotalCharge: Label;
    labelWeight: Label;
    labelWeightDisplay: Label;
    lableTotal: Label;
    layoutBOL: BillOfLadingPanel;
    panelFreight: Panel;
    panelMain: Panel;
    panelOrderNumber: Panel;
    panelQuoteNumber: Panel;
    panelTotal: Panel;
    sourceFtlQuoteOrder: DataSource;
}
