import { DataSource, Layout, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutDriverEmploymentHistory extends Layout {
    sourceDriverEmpHistory: DataSource;
    textboxAddress1: Textbox;
    textboxCity: Textbox;
    textboxEmployerName: Textbox;
    textboxFromDate: Textbox;
    textboxLeaveReason: Textbox;
    textboxNumberStates: Textbox;
    textboxPhone: Textbox;
    textboxPositionHeld: Textbox;
    textboxState: Textbox;
    textboxToDate: Textbox;
    textboxZipCode: Textbox;
}
