import { DataSource, Label, Layout, Map, Panel, Textbox } from "@mcleod/components";
import { FtlQuoteAndOrderOverview } from "../FtlQuoteAndOrderOverview";
import { OrderConfirmationHeader } from "../OrderConfirmationHeader";
import { OrderConfirmationStops } from "../OrderConfirmationStops";

export abstract class AutogenLayoutFtlOrderConfirmation extends Layout {
    labelAddlDetails: Label;
    layoutHeader: OrderConfirmationHeader;
    layoutOverView: FtlQuoteAndOrderOverview;
    layoutStops: OrderConfirmationStops;
    mapStops: Map;
    panelAddlDetails: Panel;
    panelLeftDetails: Panel;
    panelRight: Panel;
    sourceFtlOrder: DataSource;
    sourceOrderStop: DataSource;
    textboxBlnum: Textbox;
    textboxCommodity: Textbox;
    textboxConsigneeRefno: Textbox;
    textboxEquipmentTypeId: Textbox;
    textboxPlanningComment: Textbox;
    textboxShipperPo: Textbox;
    textboxShipperRef: Textbox;
    textboxWeight: Textbox;
}
