import { ChangeEvent, CityState, DropdownItem, Label, RowModeControlType, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { DateUtil, DisplayType } from "@mcleod/core";
import { validatePickupWindow, validatePrepTime } from "./../OrderValidation";
import { LtlUtil } from "./LtlUtil";
import { AutogenLayoutLtlStop } from "./autogen/AutogenLayoutLtlStop";

export class LtlStop extends AutogenLayoutLtlStop {

    private _disableValidation: boolean = false;
    private _hasValidPickupTimes: boolean = true;
    private textboxShipperEarly: Textbox;
    private textboxShipperLate: Textbox;


    public disableEdits() {
        this._disableValidation = true;
        this.tableStops.rowModeControlType = RowModeControlType.AUTO;
    }

    public get hasValidPickupTimes(): boolean {
        return this._hasValidPickupTimes;
    }

    public set hasValidPickupTimes(value: boolean) {
        this._hasValidPickupTimes = value;
    }

    /** This is an event handler for the onRowDisplay event of table1.  */
    tableStopsOnRowDisplay(event: TableRowDisplayEvent) {
        const row = event.getTableRow();
        const textboxEarlyPickup = row.findComponentById("textboxEarlyPickup") as Textbox;
        const textboxLatePickup = row.findComponentById("textboxLatePickup") as Textbox;
        const labelSequence = row.findComponentById("labelSequence") as Label;
        const labelStopType = row.findComponentById("labelStopType") as Label;
        const cityState = row.findComponentById("citystate") as CityState;
        labelSequence.caption = (row.index + 1).toString();
        labelStopType.caption = row.index == 0 ? "Pickup" : "Delivery";
        cityState.printable = true;
        if (row.index == 0) {
            row.findComponentById("labelPickupDate").visible = true;
            this.textboxShipperEarly = textboxEarlyPickup;
            this.textboxShipperLate = textboxLatePickup;
            const earlyDate = DateUtil.parseDateTime(row.data.get("sched_arrive_early"));
            const lateDate = DateUtil.parseDateTime(row.data.get("sched_arrive_late"));
            this.textboxShipperEarly.items = LtlUtil.getPickupDateItems(earlyDate);
            this.textboxShipperLate.items = this.textboxShipperEarly.items;
            this.displayDates(this.textboxShipperEarly, "sched_arrive_early", earlyDate);
            this.displayDates(this.textboxShipperLate, "sched_arrive_late", lateDate);

            this.validatePickupTimes();
            this.textboxShipperEarly.addChangeListener((event: ChangeEvent) => this.textboxPickupOnChange(event));
            this.textboxShipperLate.addChangeListener((event: ChangeEvent) => this.textboxPickupOnChange(event));
        } else {
            textboxEarlyPickup.visible = false;
            textboxLatePickup.visible = false;
        }
    }

    private displayDates(textbox: Textbox, field: string, date: Date) {
        textbox.field = field;
        textbox.displayType = DisplayType.STRING;
        textbox.displayData(textbox.boundRow, null, 0);
        if (textbox.selectedItem == null && date != null) {
            const items = textbox.items as DropdownItem[];
            const value = DateUtil.formatDateTime(date, "HH:mm");
            items.unshift({ caption: value, value: date.toString() })
            textbox.selectedItem = textbox.items[0];
        }
    }

    textboxPickupOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            (event.target as Textbox).hideDropdown(true);
            this.validatePickupTimes();
        }
    }

    async validatePickupTimes(beforePost: boolean = false) {
        if (this._disableValidation !== true) {
            try {
                this.textboxShipperEarly.enabled = false;
                this.textboxShipperLate.enabled = false;
                this.hasValidPickupTimes = true;
                const shipperStop = this.textboxShipperEarly.getRelevantModelRow();
                if (shipperStop != null) {
                    this.hasValidPickupTimes = validatePickupWindow(shipperStop);
                    if (beforePost && LtlUtil.isAsset())
                        this.hasValidPickupTimes = await validatePrepTime(shipperStop) && this.hasValidPickupTimes;
                }
            } finally {
                this.textboxShipperEarly.enabled = true;
                this.textboxShipperLate.enabled = true;
            }
        }
    }
}
