import { TableRowDisplayEvent } from "@mcleod/components";
import { StringUtil } from "@mcleod/core";
import { AutogenLayoutLocationLucene } from "./autogen/AutogenLayoutLocationLucene";

export class LocationLucene extends AutogenLayoutLocationLucene {

    tableLocationOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const data = tableRow.data;
        tableRow.forEveryChildComponent(comp => {
            if (comp.field != null) {
                comp.visible = !StringUtil.isEmptyString(data.get(comp.field));
            }
        });
    }
}
