import { Login } from "@mcleod/common";
import { ClickEvent, DropdownItem, Label, Layout } from "@mcleod/components";
import { Alignment, Api, HorizontalAlignment, Navigation, StringUtil, getLogger, getUnauthSettings } from "@mcleod/core";
import { AutogenLayoutPortalLoginFooter } from "./autogen/AutogenLayoutPortalLoginFooter";


const log = getLogger("common.Login");

export class PortalLoginFooter extends AutogenLayoutPortalLoginFooter {
    public static addFooterToLogin(loginLayout: Login) {
        const labelForgotMyPassword = new Label({ fillRow: true, align: HorizontalAlignment.RIGHT, caption: "Forgot my password", color: "primary", allowSelect: true });
        labelForgotMyPassword.addClickListener((event: ClickEvent) => {
            const label = event.target as Label;
            const options = { position: Alignment.RIGHT, shaking: true, timeout: 10000 };
            if (StringUtil.isEmptyString(loginLayout.textUserId.text)) {
                label.showTooltip("Enter a Login ID to request a password reset link.", options);
            }
            else {
                Api.post("user/reset-password-request", { user_name: loginLayout.textUserId.text }, { appendPrefix: false }).then(response => {
                    label.showTooltip("If this is a valid Login ID, we sent a password reset link to your email address.", options);
                }).catch(err => {
                    label.showTooltip("There was an error sending the password reset link.", options);
                    log.error("Password reset error", err)
                });
            }
        });

        loginLayout.panelContentLogin.add(labelForgotMyPassword);
        const footer = Layout.getLayout("portal-common/PortalLoginFooter") as PortalLoginFooter;
        loginLayout.panelContentLogin.add(footer);
        const settings = getUnauthSettings()?.company_settings;
        footer.addLayoutLoadListener((event) => {
            if (settings["allow_driver_recruiting"] == false) {
                footer.labelDriverApplication.visible = false;
            }
            const searchItems = footer.textboxSearchType.items as DropdownItem[];
            if (settings["is_brokerage_ltl"] === true) {
                searchItems.push({ value: "CarrierProNum", caption: "Carrier Pro #" });
                searchItems.push({ value: "ShipperPONum", caption: "Shipper PO #" });
            }
            if (settings["is_asset_ltl"] == true)
                searchItems.push({ value: "ProNum", caption: "Pro #" });

            footer.buttonTrack.addClickListener((event: ClickEvent) => {
                const searchType = footer.textboxSearchType.selectedItem?.value;
                const trackingNum = footer.textboxTrackingNum.text;
                if (!footer.textboxSearchType.validateSimple())
                    footer.textboxSearchType.showTooltip("Search Type Required");
                if (!footer.textboxTrackingNum.validateSimple())
                    footer.textboxTrackingNum.showTooltip("Tracking Number Required");

                if (footer.textboxSearchType.validateSimple() && footer.textboxTrackingNum.validateSimple())
                    Navigation.navigateTo(`portal-customer/TrackWithoutLogin?search_type=${searchType}&search_value=${trackingNum}`);
            });
        });
    }

}

