import { ClickEvent } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { AutogenLayoutLocationManualAdd } from "./autogen/AutogenLayoutLocationManualAdd";

export class LocationManualAdd extends AutogenLayoutLocationManualAdd {

    /** This is an event handler for the onClick event of buttonUseThisAddress.  */
    buttonUseThisAddressOnClick(event: ClickEvent) {
        let location = "";
        const modelRow = new ModelRow(this.mainDataSource.url, false, null);

        if (this.textboxCompanyName.text) {
            //locationRow.set("name", this.textboxCompanyName.text);
            location = this.textboxCompanyName.text;
            modelRow.set("name", this.textboxCompanyName.text);
        }
        if (this.textboxStreetName.text) {
            //locationRow.set("address1",this.textboxStreetName.text);
            location = location + " " + this.textboxStreetName.text + ", ";
            modelRow.set("address1", this.textboxStreetName.text);
        }
        if (this.citystate1.textCombined) {
            location = location + this.citystate1.textCombined.text;
            //row.set("citystatezip", this.citystate1.textCombined.text);
            modelRow.set("city_name", this.citystate1.textCombined.getFirstLookupModelData()?.data["name"]);
            modelRow.set("state", this.citystate1.textCombined.getFirstLookupModelData()?.data["state_id"]);
            modelRow.set("zip_code", this.citystate1.textCombined.getFirstLookupModelData()?.data["zip_code"]);
            modelRow.set("city_id", this.citystate1.textCombined.getFirstLookupModelData()?.data["id"]);
        }

        if (this.checkboxSave.checked)
            modelRow.set("save_address", true);

        this.mainDataSource.data.push(modelRow);
    }
}
