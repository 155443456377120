import { ChangeEvent, Component, Label, Layout, Panel, Tab } from "@mcleod/components";
import { HorizontalAlignment, getUnauthSettings } from "@mcleod/core";
import { TableCarrierAccepted } from "./TableCarrierAccepted";
import { TableCarrierAvailable } from "./TableCarrierAvailable";
import { TableCarrierDeliveredOrders } from "./TableCarrierDeliveredOrders";
import { TableCarrierLoadboard } from "./TableCarrierLoadboard";
import { TableCarrierOfferHistory } from "./TableCarrierOfferHistory";
import { AutogenLayoutCarrierPortal } from "./autogen/AutogenLayoutCarrierPortal";

export class CarrierPortal extends AutogenLayoutCarrierPortal {

    override onLoad() {
        this.showSelectedTabData();
        if (getUnauthSettings()?.company_settings["loadboard_active"] === true)
            this.tabLoadboard.visible = true;

        this.tabset.components.forEach((tab: Tab, index) => {
            const tabLayout = tab.components?.[0] as Layout;

            const dataSource = tabLayout?.mainDataSource;
            if (dataSource != null) {
                dataSource.addAfterExecutionListener(() => {
                    if (tab == this.tabset.getActiveTab()) {
                        this.setTableEmptyCaption(tab);
                        let countLabel = this.tabset.getActiveTab().heading.findComponentById("labelCount") as Label;
                        if (countLabel == null) {
                            countLabel = new Label({ id: "labelCount", rowBreak: false, imageColor: "primary" });
                            this.tabset.getActiveTab().heading.add(countLabel);
                        }
                        countLabel.caption = `(${dataSource.data?.length ?? 0})`;
                    }
                })
            }
            if (tab != this.tabset.getActiveTab() && tab.heading.findComponentById("labelCount") != null)
                tab.heading.removeAt(1);
        });
    }
    afterTabSelection(event: ChangeEvent) {
        this.showSelectedTabData();
    }

    private showSelectedTabData() {
        const tabLayout = this.tabset.getActiveTab()?.components?.[0] as Layout;
        if (tabLayout instanceof TableCarrierLoadboard) {
            tabLayout.panelFilter.forEveryChildComponent((component: Component) => {
                tabLayout.clearFields(component);
            });
        }
        const wwwControlSettings = getUnauthSettings().company_settings;
        const loadboardShowB4Search = wwwControlSettings["loadboard_show_b4_search"];
        if (tabLayout?.mainDataSource) {
            // for all layouts except TableCarrierOfferHistory, pass in empty filter so beforeExecutionListeners can modify the filter
            const filter = (tabLayout instanceof TableCarrierOfferHistory) ? null : {}
            if (tabLayout instanceof TableCarrierLoadboard) {
                if (loadboardShowB4Search) {
                    tabLayout.mainDataSource.search(filter)
                }
            } else {
                tabLayout.mainDataSource.search(filter);
            }
        }
    }

    tabsetBeforeTabSelection(event: ChangeEvent) {
        const tab = this.tabset.getActiveTab();
        if (tab.heading.findComponentById("labelCount") != null)
            tab.heading.removeAt(1);
    }

    private setTableEmptyCaption(tab: Tab) {
        const tabLayout = tab.components?.[0] as Layout;
        if (tabLayout instanceof TableCarrierAccepted) {
            tabLayout.tableAccepted.emptyComponent = createTableEmptyComponent("No loads available for display");
        }
        else if (tabLayout instanceof TableCarrierLoadboard) {
            let captionToUse = "No loads available for display";
            const wwwControlSettings = getUnauthSettings().company_settings;
            const loadboardShowB4Search = wwwControlSettings["loadboard_show_b4_search"];
            if (!loadboardShowB4Search) {
                captionToUse = "Enter search criteria and click search.";
            }
            tabLayout.tableLoadboard.emptyComponent = createTableEmptyComponent(captionToUse);
        } else if (tabLayout instanceof TableCarrierAvailable) {
            tabLayout.tableAvailable.emptyComponent = createTableEmptyComponent("No loads available for display");
        } else if (tabLayout instanceof TableCarrierDeliveredOrders)
            tabLayout.tableCarrierDeliveredOrders.emptyComponent = createTableEmptyComponent("No loads available for display");
    }
}

export function createTableEmptyComponent(caption: string): Panel {
    return new Panel({
        fillRow: true, align: HorizontalAlignment.CENTER, marginTop: 20,
        components: [new Label({ caption: caption, fontBold: true })]
    });
}
