import { CommonDialogs } from "@mcleod/common";
import { Button, ButtonProps, ClickEvent, Dialog, HorizontalSpacer, Label, Panel, Snackbar, Textbox } from "@mcleod/components";
import { Api, Clipboard, HorizontalAlignment } from "@mcleod/core";
export class ShareOrder {

    static onClick(event: ClickEvent, orderIdStringOrFunction: string | (() => string)): void {
        const basicDropdownProps: Partial<ButtonProps> = {
            borderWidth: 0,
            borderRadius: 0,
            marginLeft: 0,
            paddingLeft: 8,
            paddingRight: 8,
            width: "100%",
            align: HorizontalAlignment.LEFT,
            fontSize: "medium"
        };
        let orderId = null;
        if (orderIdStringOrFunction instanceof Function)
            orderId = orderIdStringOrFunction();
        else
            orderId = orderIdStringOrFunction;

        (event.target as Button).dropdownItems = [
            new Label({ caption: "Copy link to clipboard", onClick: () => this.createShareLink(orderId), imageName: "clipboard", ...basicDropdownProps }),
            new Label({ caption: "Share link via email", onClick: () => this.emailLink(orderId), imageName: "mail", ...basicDropdownProps }),
        ]
    }

    static createShareLink(orderId: string) {
        Api.post("portal/customer/get-order-link",
            {
                order_id: orderId
            }).then(response => {
                Clipboard.copyText(response.data[0].link);
                Snackbar.showSnackbar("A link to this order been copied to your clipboard.");
            });
    }


    static emailLink(orderId: string) {
        const inputPanel = new Panel({ id: "emailInputPanel", fillHeight: true, fillRow: true, minWidth: "500" });
        const emailAddressesTextbox = new Textbox({ caption: "Email Address", id: "emailAdresses", required: true, fillRow: true, tooltip: "For multiple emails, separate with a comma" });
        const commentsTextbox = new Textbox({ caption: "Comments (optional)", id: "comments", multiline: true, fillRow: true });
        const sendEmailButton = new Button({ caption: "Send", onClick: (event) => this.sendEmail(emailDialog, orderId), color: "primary", marginTop: 18 });
        const horizontalSpacer = new HorizontalSpacer({});
        inputPanel.add(emailAddressesTextbox, commentsTextbox, horizontalSpacer, sendEmailButton);
        const emailDialog = CommonDialogs.createDialog(inputPanel, { title: `Share Order ${orderId} via Email`, okVisible: false });
        emailDialog.show().then((message) => {
            if (message)
                Snackbar.showSnackbar(message)
        });
    }

    static validateMultipleEmails(emailTextbox: Textbox): boolean {
        let passed = true;
        if (emailTextbox.text.includes(",")) {
            const emailList = emailTextbox.text.split(",");
            emailList.forEach(email => {
                const value = email.replace(" ", "");
                if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/.test(value)) {
                    emailTextbox.validationWarning = "Please enter a valid list of email addresses, seperated by a comma.";
                    passed = false;
                }
            })
        }
        else {
            if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/.test(emailTextbox.text)) {
                emailTextbox.validationWarning = "Please enter a valid email address.";
                passed = false;
            }
        }
        return passed;

    }

    static sendEmail(emailDialog: Dialog, orderId: string) {
        const emailAddresses = emailDialog.findComponentById("emailAdresses") as Textbox;
        const comments = emailDialog.findComponentById("comments") as Textbox;
        if (emailDialog.validateSimple() && this.validateMultipleEmails(emailAddresses)) {
            Api.post("portal/customer/email-order",
                {
                    order_id: orderId,
                    recipients: emailAddresses.text.replace(",", ";"),
                    comment: comments.text
                }).then(response => {
                    emailDialog.close(response.data[0].response);
                });
        }
    }

}
