import { Button, CityState, DataSource, Layout, Panel, Textbox } from "@mcleod/components";
import { DriverChoice } from "../DriverChoice";

export abstract class AutogenLayoutDriverChoiceManualSearch extends Layout {
    buttonManualSearch: Button;
    citystateDestCity: CityState;
    citystateShipCity: CityState;
    layoutDriverChoice: DriverChoice;
    panelSearch: Panel;
    sourceDriverChoice: DataSource;
    textboxDaysOut: Textbox;
    textboxDestRadius: Textbox;
    textboxShipRadius: Textbox;
    textboxTrailerType: Textbox;
}
