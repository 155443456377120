import { Button, DataSource, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutPortalSettlementHistory extends Layout {
    buttonSearch: Button;
    labelTitle: Label;
    panelPageTitle: Panel;
    sourceSettlement: DataSource;
    tableSettlement: Table;
    textboxCarrierProNbr: Textbox;
    textboxCheckNumber: Textbox;
    textboxDeliveryDateEnd: Textbox;
    textboxDeliveryDateStart: Textbox;
    textboxOrderId: Textbox;
    textboxPayDateEnd: Textbox;
    textboxPayDateStart: Textbox;
}
