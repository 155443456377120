import { MapPin, PanelProps } from "@mcleod/components";
import { ModelSearchResult } from "@mcleod/core";
import { AutogenLayoutOrderMapView } from "./autogen/AutogenLayoutOrderMapView";

export class OrderMapView extends AutogenLayoutOrderMapView {

    private stopsSet = new Set();
    override onLoad() {
        this.addLayoutLoadListener(event => {
            this.map1.fitPins();
        });
    }

    public setUpMap(response: ModelSearchResult) {
        const row = response.modelRows[0].data;

        const positions = row.positions;
        for (let x = positions.length - 1; x > -1; x--) {
            this.addPositionPin(positions[x]);
        }
        const popupProps: Partial<PanelProps> = {};
        popupProps.top = window.innerHeight * .30;
        popupProps.left = window.innerWidth * .025;
        popupProps.width = window.innerWidth * .95;
        popupProps.height = window.innerHeight * .55;
        this.map1.setPopupProps(popupProps);
    }

    private addPositionPin(position: any) {
        const pin = new MapPin(position.latitude, position.longitude);

        if (position.image != null)
            pin.image = position.image;
        else
            pin.image = "map-pin-white-dot-large";

        pin.tooltip = position.descr;
        pin.caption = position.sequence;
        if (position.caption_color != null)
            pin.captionColor = position.caption_color;
        else
            pin.captionColor = "Blue";
        pin.layoutName = null;

        const key = position.latitude + position.longitude;
        const descr = position.descr;
        if (descr != null && descr.startsWith("Stop")) {
            if (!this.stopsSet.has(key)) {
                this.map1.addPin(pin);
                this.stopsSet.add(key);
            }
        }
        else {
            pin.clusterable = false;
            this.map1.addPin(pin);
        }
    }
}
