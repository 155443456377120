import { Button, Image, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutDriverApplicationCongrats extends Layout {
    buttonBackToSignIn: Button;
    image1: Image;
    labelAppReceived: Label;
    labelCongratulations: Label;
    labelContact: Label;
    labelContactEmail: Label;
    labelContactPhone: Label;
    labelDriverApplication: Label;
    panel1: Panel;
}
