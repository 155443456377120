import { CommonDialogs } from "@mcleod/common";
import { Api, StringUtil, UrlUtil, getLogger } from "@mcleod/core";
import { AutogenLayoutContinuousMove } from "./autogen/AutogenLayoutContinuousMove";

const log = getLogger("portal-carrier.offer.ContinuousMove");

export class ContinuousMove extends AutogenLayoutContinuousMove {

    override async onLoad() {
        const props = UrlUtil.getPropsFromUrl(document.location.search);
        this.labelResponse.busy = true;
        Api.post("portal/carrier/carrier-continuous-move", { token: props.token }).then(response => {
            log.debug({ response });
            if (!StringUtil.isEmptyString(response.data[0].success)) {
                this.labelResponse.caption = response.data[0].success
            }
        }).catch(err => {
            this.labelResponse.caption = "Error sending email"
            CommonDialogs.showError(err);
        }).finally(() => this.labelResponse.busy = false);
    }
}
