import { Button, DataSource, Label, Layout, Panel, Switch } from "@mcleod/components";

export abstract class AutogenLayoutDriverChoice extends Layout {
    buttonSearchPreferences: Button;
    labelNoMatchingLoadsWereFound: Label;
    panel1: Panel;
    panelTable: Panel;
    sourceDriverChoice: DataSource;
    switchSwitch1: Switch;
}
