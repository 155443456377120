import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";
import { OrderTermsConditions } from "../../OrderTermsConditions";
import { FreightItemTable } from "../FreightItemTable";
import { HandlingRequirements } from "../HandlingRequirements";

export abstract class AutogenLayoutLtlOrder extends Layout {
    buttonCancel: Button;
    buttonSubmitOrder: Button;
    horizontalspacer2: HorizontalSpacer;
    label1: Label;
    labelAddlDetails: Label;
    labelPickupAndDelivery: Label;
    labelTitlePaymentTerms: Label;
    labelWelcome: Label;
    layoutFGIs: FreightItemTable;
    layoutHdrs: HandlingRequirements;
    layoutOrderTerms: OrderTermsConditions;
    panel1: Panel;
    panelAddlDetails: Panel;
    panelMain: Panel;
    panelPaymentTerms: Panel;
    sourceFreightItem: DataSource;
    sourceHandlingReq: DataSource;
    sourceLtlOrder: DataSource;
    sourceStop: DataSource;
    tableStops: Table;
    textboxBlnum: Textbox;
    textboxBolNote: Textbox;
    textboxConsigneeRefno: Textbox;
    textboxPaymentTerms: Textbox;
    textboxPlanningComment: Textbox;
    textboxShipperPo: Textbox;
    textboxShipperRef: Textbox;
}
