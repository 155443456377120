import { DataSource, DropdownItem, Snackbar } from "@mcleod/components";
import { Api, DateUtil, FileUtil, NumberUtil, getUnauthSettings } from "@mcleod/core";
import { RowLtlOrderFreightGroupItem } from "../../../customer/dispatch/src/models/ModelLtlOrderFreightGroupItem";
import { RowLtlQuoteFreightGroupItem } from "../../../customer/dispatch/src/models/ModelLtlQuoteFreightGroupItem";
import { RowLtlQuoteFreightGroupItemFields } from "../../../customer/dispatch/src/models/autogen/AutogenModelLtlQuoteFreightGroupItem";
export class LtlUtil {


    public static isLtlEnabled(): boolean {
        return getUnauthSettings()?.company_settings?.["is_ltl"] == true;
    }

    public static isAsset(): boolean {
        return getUnauthSettings()?.company_settings?.["is_asset_ltl"] == true;
    }

    public static isBrokerage(): boolean {
        return getUnauthSettings()?.company_settings?.["is_brokerage_ltl"] == true;
    }

    public static getTotalFormattedWeight(dataSource: DataSource<RowLtlQuoteFreightGroupItem>): string {
        return NumberUtil.formatDecimal(this.getTotalWeight(dataSource) ?? 0, "#,###.#") + " lbs"
    }

    public static getTotalWeight(dataSource: DataSource<RowLtlQuoteFreightGroupItem>): number {
        return this.getFgiTotal(dataSource, "weight");
    }

    public static formatTotalWeight(weight: number): string {
        return NumberUtil.formatDecimal(weight ?? 0, "#,###.#") + " lbs"
    }

    public static getFgiTotal(dataSource: DataSource<RowLtlQuoteFreightGroupItem>, field: keyof RowLtlQuoteFreightGroupItemFields): number {
        const data = dataSource?.data;
        if (data != null && data.length > 0)
            return data.map(row => parseFloat(row.get(field, 0).toString().replace(/,/g, ''))).reduce((prev, next) => prev + next);
        return 0;
    }

    public static getOverallClassCode(dataSource: DataSource<RowLtlQuoteFreightGroupItem | RowLtlOrderFreightGroupItem>): string {
        let classCode = null;
        for (const row of dataSource?.data) {
            const rowClass = row.get("nmfc_class_code");
            if (classCode == null) {
                classCode = rowClass;
            } else if (rowClass != classCode) {
                classCode = "Multiple";
                break;
            }
        }
        return classCode;
    }


    public static getPickupDateItems(date: any): DropdownItem[] {
        const stopDate = DateUtil.parseDateTime(date);
        const timeItems = [];
        let start = new Date(getUnauthSettings().company_settings["earliest_pickup"]);
        let end = new Date(getUnauthSettings().company_settings["ord_latest_pickup_time"]);
        if (isNaN(start.getTime())) {
            start = new Date(stopDate);
            start.setHours(8, 0, 0, 0);
        }
        else {
            const temp = start;
            start = new Date(stopDate);
            start.setHours(temp.getHours(), temp.getMinutes(), 0, 0);
        }
        if (isNaN(end.getTime())) {
            end = new Date(stopDate);
            end.setHours(17, 0, 0, 0);
        }
        else {
            const temp = end;
            end = new Date(stopDate);
            end.setHours(temp.getHours(), temp.getMinutes(), 0, 0);
        }
        for (let d = start; d <= end; d.setMinutes(d.getMinutes() + 30)) {
            const value = DateUtil.formatDateTime(d, "HH:mm");
            timeItems.push({ caption: value, value: d.toString() });
        }
        return timeItems;
    }


    public static downloadBOL(orderId: string) {
        if (orderId != null) {
            const todaysDate = DateUtil.formatDate(new Date(), "MM/dd/yyyy");
            const fileName = `Order ${orderId}-BOL-${todaysDate}.pdf`;
            const progressSnack: Snackbar = Snackbar.showDownloadSnackbar("Bill of Lading", "We are generating your Bill of Lading and it will download automatically.", { persist: true });
            Api.post("portal/reports/bill-of-lading", { order_id: orderId }).then(response => {
                const report = response?.data[0]?.bol_report;
                if (report != null) {
                    FileUtil.downloadBase64AsFile(report, fileName);
                    Snackbar.showSnackbar("Downloaded " + fileName);
                    progressSnack.dismiss();
                }
            });
        }
    }

    /** This is an event handler for the onClick event of buttonQuoteSheet.  */
    public static downloadQuoteSheet(quoteId: string) {
        if (quoteId != null) {
            const todaysDate = DateUtil.formatDate(new Date(), "MM/dd/yyyy");
            const fileName = `Quote ${quoteId}-Quote-Sheet-${todaysDate}.pdf`;
            const progressSnack: Snackbar = Snackbar.showDownloadSnackbar("Quote Sheet", "We are generating your Quote Sheet and it will download automatically.", { persist: true });
            Api.post("portal/reports/quote-sheet", { quote_id: quoteId }).then((response) => {
                const report = response?.data[0]?.quote_sheet_report;
                if (report != null) {
                    FileUtil.downloadBase64AsFile(report, fileName);
                    Snackbar.showSnackbar("Downloaded " + fileName);
                    progressSnack.dismiss();
                }
            }).catch((err) => {
                progressSnack.dismiss();
                Snackbar.showWarningSnackbar(err, { persist: true });
            })
        }
    }

}
