import { CommonDialogs } from "@mcleod/common";
import { Api, UrlUtil } from "@mcleod/core";
import { AutogenLayoutPNNUnsubscribe } from "./autogen/AutogenLayoutPNNUnsubscribe";

export class PNNUnsubscribe extends AutogenLayoutPNNUnsubscribe {
    override async onLoad() {
        const props = UrlUtil.getPropsFromUrl(document.location.search);
        this.labelResponse.busy = true;
        Api.post("portal/carrier/pnn-unsubscribe", {
            company_id: props.STCID,
            master_id: props.STMID,
            master_type: props.STMT,
            sequence: props.STS
        }).then(() => {
            this.labelResponse.caption = "You have been successfully unsubscribed.";
        }).catch(err => {
            this.labelResponse.caption = "Error unsubscribing";
            CommonDialogs.showError(err);
        }).finally(() => this.labelResponse.busy = false);
    }
}
