import { Button, DataSource, Label, Layout, Panel, Step, Stepper, Table, Textbox } from "@mcleod/components";
import { CarrierSelection } from "../CarrierSelection";
import { HandlingRequirements } from "../HandlingRequirements";
import { LtlQuoteDetails } from "../LtlQuoteDetails";
import { QuoteFreightItemTable } from "../QuoteFreightItemTable";

export abstract class AutogenLayoutLtlQuote extends Layout {
    buttonQuoteSheet: Button;
    label1: Label;
    labelFreightInfo: Label;
    labelLabel2: Label;
    labelOverviewPickupDate: Label;
    labelPickupDate: Label;
    labelPickupDelivery: Label;
    labelQuoteNumber: Label;
    labelTitlePaymentTerms: Label;
    labelWelcomeMsg: Label;
    layoutCarrierSelection: CarrierSelection;
    layoutFreightItems: QuoteFreightItemTable;
    layoutHdr: HandlingRequirements;
    layoutQuoteDetails: LtlQuoteDetails;
    panel3: Panel;
    panel4: Panel;
    panel5: Panel;
    panel6: Panel;
    panel7: Panel;
    panelMain: Panel;
    panelPaymentTerms: Panel;
    panelPickupDate: Panel;
    panelStep1: Panel;
    panelStep1Acc: Panel;
    panelStep2: Panel;
    panelStops: Panel;
    sourceHdr: DataSource;
    sourceLtlQuoteOrder: DataSource;
    sourceQuoteStops: DataSource;
    stepCarrierSelection: Step;
    stepDetails: Step;
    stepOverview: Step;
    stepper: Stepper;
    tableStopOverview: Table;
    tableStops: Table;
    textPickupDate: Textbox;
    textboxFreightClass: Textbox;
    textboxPaymentTerms: Textbox;
    textboxTotalWeightDisplayValue: Textbox;
}
