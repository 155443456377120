import { ClickEvent } from "@mcleod/components";
import { ShareOrder } from ".././ShareOrder";
import { LtlUtil } from "./LtlUtil";
import { AutogenLayoutBillOfLadingPanel } from "./autogen/AutogenLayoutBillOfLadingPanel";

export class BillOfLadingPanel extends AutogenLayoutBillOfLadingPanel {
    private _orderId: string | ((args?: any) => string);

    private getOrderId(): string {
        if (this._orderId instanceof Function)
            return this._orderId();
        else
            return this._orderId;
    }

    public set orderId(value: string) {
        this._orderId = value;
        this.labelOrderId.caption = value;
    }

    buttonShareOnClick(event: ClickEvent) {
        ShareOrder.onClick(event, this.getOrderId());
    }

    buttonBOLOnClick(event: ClickEvent) {
        LtlUtil.downloadBOL(this.getOrderId());
    }
}
