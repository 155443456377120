import { DataDisplayEvent, DataSource, DataSourceMode, Image, ImageType } from "@mcleod/components";
import { HorizontalAlignment, ModelRow, getAuthSettings } from "@mcleod/core";
import { LtlUtil } from "./LtlUtil";
import { AutogenLayoutQuoteAndOrderOverview } from "./autogen/AutogenLayoutQuoteAndOrderOverview";

export class QuoteAndOrderOverview extends AutogenLayoutQuoteAndOrderOverview {
    private _quoteId: string;
    private _orderId: string;

    override onLoad() {
        this.panelCarrier.visible = LtlUtil.isBrokerage();
        if (getAuthSettings()?.user_settings?.user_type == "L") {
            this.panelMain.padding = 0;
            this.panelMain.components.forEach(comp => {
                if (comp.id == "panelOrderNumber")
                    comp.setProps({ padding: 0, align: HorizontalAlignment.CENTER, visible: true, borderWidth: 0 })
                else
                    comp.visible = false;
            });
        }
    }

    setup(orderSource: DataSource, selectedRate: ModelRow<any>, isOrder: boolean = false) {
        this.labelDetailTotal.displayData(orderSource.activeRow, null, 0);
        this.labelTotalCharge.displayData(orderSource.activeRow, null, 0);
        if (isOrder)
            this.orderId = orderSource.activeRow.get("id");
        else
            this.quoteId = orderSource.activeRow.get("id");
        if (LtlUtil.isAsset()) {
            this.addRevenueDetails(orderSource.activeRow);
        }

        if (selectedRate != null) {
            this.sourceRates.setRowsAndMode(DataSourceMode.UPDATE, [selectedRate]);
            this.labelDetailTotal.displayData(this.sourceRates.activeRow, null, 0);
            this.labelTotalCharge.displayData(this.sourceRates.activeRow, null, 0);
        } else {
            this.panelCarrier.visible = false;
        }
    }

    public get quoteId(): string {
        return this._quoteId;
    }

    public set quoteId(value: string) {
        this._quoteId = value;
        this.syncQuoteOrderPanels();
    }

    public get orderId(): string {
        return this._orderId;
    }
    public set orderId(value: string) {
        this._orderId = value;
        this.syncQuoteOrderPanels();
    }

    syncQuoteOrderPanels() {
        this.labelQuoteId.caption = this.quoteId;
        this.layoutBOL.orderId = this.orderId;
        this.panelQuoteNumber.visible = this.orderId == null;
        this.panelOrderNumber.visible = this.orderId != null;
    }

    /** This is an event handler for the onDisplay event of sourceRates.  */
    sourceRatesOnDisplay(event: DataDisplayEvent) {
        this.addCarrierImage();
        this.addRevenueDetails(this.sourceRates.activeRow);
    }

    addRevenueDetails(parentRow: ModelRow) {
        const revDetails = parentRow?.get("revenue_details");
        this.sourceRevenueDetail.data = [];
        if (revDetails != null) {
            revDetails?.forEach(data => {
                const row = new ModelRow(this.sourceRevenueDetail.url, false, { ...data });
                this.sourceRevenueDetail.data.push(row);
            });
            this.sourceRevenueDetail.displayDataInBoundComponents();
        }
    }

    sourceQuoteFreightGroupItemOnDisplay(event: DataDisplayEvent) {
        this.calcFreightTotals();
    }

    calcFreightTotals() {
        this.textboxWeight.text = LtlUtil.getTotalFormattedWeight(this.sourceQuoteFreightGroupItem);
        this.textboxFreightClass.text = LtlUtil.getOverallClassCode(this.sourceQuoteFreightGroupItem);
    }


    getFgiTotal(field: string): number {
        const value = this.sourceQuoteFreightGroupItem.data.
            map(row => parseFloat(row.get(field, 0).toString().replace(/,/g, ''))).reduce((prev, next) => prev + next);
        return value;
    }

    addCarrierImage() {
        if (this.sourceRates.activeRow != null) {
            if (!this.sourceRates.activeRow?.isNull("image_bytes")) {
                this.panelImage.removeAll();
                const img = new Image({
                    id: "carrierImg", imageType: ImageType.IMG,
                    preserveAspectRatio: true, height: 75, width: 150
                });

                img.imageBytes = this.sourceRates.activeRow?.get("image_bytes");
                this.panelImage.add(img);
            }
        }
    }
}
