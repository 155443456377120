import { Image, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutOrderQuoteButtonPanel extends Layout {
    imageAddOrder: Image;
    imageGetFtlQuote: Image;
    imageGetQuote: Image;
    labelAddOrder: Label;
    labelGetLtlOrder: Label;
    labelGetQuote: Label;
    panelAddFTLQuote: Panel;
    panelAddLtlOrder: Panel;
    panelAddOrder: Panel;
    panelAddQuote: Panel;
}
