import { CommonDialogs } from "@mcleod/common";
import { BlurEvent, Button, ClickEvent, DataDisplayEvent, DataSourceExecutionEvent, Label, Layout, Panel, Snackbar, TableRow, TableRowExpansionEvent, Textbox } from "@mcleod/components";
import { Api, DateUtil, FileUtil, ModelRow, getAuthSettings } from "@mcleod/core";
import { CarrierDeliveredOrdersDetails } from "./CarrierDeliveredOrdersDetails";
import { CarrierDispatch } from "./CarrierDispatch";
import { RateConfirmationViewer } from "./RateConfirmationViewer";
import { AutogenLayoutTableCarrierAccepted } from "./autogen/AutogenLayoutTableCarrierAccepted";

export class TableCarrierAccepted extends AutogenLayoutTableCarrierAccepted {

    carrierDeliveredOrdersDetailsLayout: CarrierDeliveredOrdersDetails;
    tabSelected: string;

    override onLoad() {
        this.mainDataSource.addBeforeExecutionListener((event: DataSourceExecutionEvent) => event.filter.type = "accepted");
    }

    /** This is an event handler for the onRowExpand event of tableAccepted.  */
    tableAcceptedOnRowExpand(event: TableRowExpansionEvent) {
        const tableData = event.getTableRow().data;
        const tableRow = event.getTableRow() as TableRow;
        const email = tableData?.get("contact_email");
        if (email != null) {
            const id = event.getTableRow().data?.get("order_id");
            const subject = `Question about Accepted Order ${id}`;
            const contactEmailLabel: Label = event.table["labelContactEmail"]
            contactEmailLabel.link = `mailto:${email}?subject=${subject}`;
        }

        const orderModelRow = event.getTableRow()?.data as ModelRow;
        const movementId = orderModelRow?.get("movement_id");
        const orderId = orderModelRow?.get("order_id");
        const originLoc = orderModelRow?.get("origin_loc");
        const destinationLoc = orderModelRow?.get("destination_loc");
        const commodity = orderModelRow?.get("commodity");

        const expandPanel = event.expandComponentParent as Panel;
        const buttonPanel = expandPanel.findComponentById("panelAddActions") as Panel;
        const addCallinsButton = buttonPanel.findComponentById("buttonAddCallin") as Button;
        const addImagesButton = buttonPanel.findComponentById("buttonAddImage") as Button;
        this.carrierDeliveredOrdersDetailsLayout = expandPanel.findComponentById("layoutCallinImage") as CarrierDeliveredOrdersDetails;

        const labelCallinStatus = event.getTableRow().findComponentById("labelCallinStatus") as Label;
        const labelImageStatus = event.getTableRow().findComponentById("labelImageStatus") as Label;

        const imagePermission = getAuthSettings().permissions?.denied?.InternetModule?.["InternetModuleDocumentRetrieval"] == null
        const callinPermission = getAuthSettings().permissions?.denied?.InternetModule?.["InternetModuleCarrierEnteredCallins"] == null
        const dispatchPermission = getAuthSettings().permissions?.denied?.InternetModule?.["InternetModuleCarrierDispatch"] == null

        this.carrierDeliveredOrdersDetailsLayout.addLayoutLoadListener(event => {

            if (callinPermission === false && imagePermission === false) {
                expandPanel.remove(buttonPanel);
                this.carrierDeliveredOrdersDetailsLayout.removeTabset();
                return;
            }

            this.carrierDeliveredOrdersDetailsLayout.setSelectedTabset(this.tabSelected);
            if (callinPermission) {
                this.carrierDeliveredOrdersDetailsLayout.tabCallins.dataSource.search({ movement_id: movementId, order_id: orderId }).then(
                    result => {
                        this.carrierDeliveredOrdersDetailsLayout.setNoCallins();
                    }
                );
                this.carrierDeliveredOrdersDetailsLayout.setupCarrierCallinsLayout(movementId, orderId, originLoc, destinationLoc,
                    commodity, addCallinsButton, labelCallinStatus);
            }
            else {
                buttonPanel.remove(addCallinsButton);
                this.carrierDeliveredOrdersDetailsLayout.removeCarrierCallins();
            }

            if (imagePermission) {
                this.carrierDeliveredOrdersDetailsLayout.tabImages.dataSource.search({ movement_id: movementId, order_id: orderId }).then(
                    result => {
                        this.carrierDeliveredOrdersDetailsLayout.setNoImages();
                    }
                );
                this.carrierDeliveredOrdersDetailsLayout.setupCarrierImagesLayout(movementId, orderId, addImagesButton, labelImageStatus);
            }
            else {
                buttonPanel.remove(addImagesButton);
                this.carrierDeliveredOrdersDetailsLayout.removeCarrierImages();
            }

        });

        const rateConButton = expandPanel.findComponentById("buttonRateConfirmation") as Button
        rateConButton.addClickListener((event: ClickEvent) => {
            this.displayRateCon(tableData?.get("order_id"), tableData?.get("movement_id"));
        })

        const dispatchButton = expandPanel.findComponentById("buttonDispatch") as Button
        if (dispatchPermission == false)
            dispatchButton.visible = false;
        else
            dispatchButton.addClickListener((event: ClickEvent) => {
                this.displayDispatch(tableData?.get("order_id"), tableData?.get("movement_id"), tableRow);
            })

    }


    displayRateCon(orderId: String, movementId: String) {
        const filter = [
            {
                order_id: orderId,
                movement_id: movementId
            }
        ];
        Api.post("portal/carrier/order_confirmation", filter).then(response => {
            if (response != null) {
                const layout = Layout.getLayout("portal-carrier/RateConfirmationViewer", {
                    maxWidth: 1100, width: window.innerWidth * .75, height: window.innerHeight,
                    backgroundColor: "defaultBackground", top: 30,
                    borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary"
                });
                layout.addLayoutDataLoadListener(event => {
                    const layout = event.target as RateConfirmationViewer;
                    if (layout != null && response.data[0] != null) {
                        const base64 = window.atob(response.data[0]?.rate_confirmation);
                        const len = new Array(base64.length);
                        for (let i = 0; i < base64.length; i++)
                            len[i] = base64.charCodeAt(i);
                        const byteArray = new Uint8Array(len);
                        const blob = new Blob([byteArray], { type: 'application/pdf' });
                        let fileURL = URL.createObjectURL(blob);
                        const viewPDF = document.createElement("object"); //is is safe to use iframe here???
                        fileURL += "#toolbar=0&navpanes=0"; // this doesn't work for Firefox
                        viewPDF.type = "application/pdf";
                        viewPDF.data = fileURL;
                        viewPDF.style.height = "100%";
                        viewPDF.style.width = "100%";
                        layout.panelRateConView._element.appendChild(viewPDF);
                        layout.buttonDownloadRateConfirmation.addClickListener((event: ClickEvent) => {
                            const progressSnack: Snackbar = Snackbar.showDownloadSnackbar("Rate Confirmation", "We are generating your rate confirmation and it will download automatically.");
                            FileUtil.downloadBase64AsFile(response.data[0].rate_confirmation, `Rate Confirmation ${orderId}.pdf`);
                            Snackbar.showSnackbar("Downloaded " + `Rate Confirmation ${orderId}.pdf`);
                            progressSnack.dismiss();
                        });
                    }
                    else if (response.data[0] == null) {
                        layout.panelRateConView.add(new Label({ caption: "No rate confirmation returned" }));
                    }
                });
                CommonDialogs.showDialog(layout, { title: "Rate Confirmation" })
            }
        });
    }

    /** This is an event handler for the onClick event of buttonRateConfirmation.  */
    buttonRateConfirmationOnClick(event: ClickEvent) {
        const modelRow = event.target["row"]?.data as ModelRow;
        this.displayRateCon(modelRow?.get("order_id"), modelRow?.get("movement_id"));
    }

    labelCallinStatusOnClick(event: ClickEvent) {
        this.tabSelected = "Callins";
        this.setTabset(event);
    }

    labelImageStatusOnClick(event: ClickEvent) {
        this.tabSelected = "Images";
        this.setTabset(event);
    }

    setTabset(event: ClickEvent) {
        const tableRow = event.target["row"] as TableRow
        if (tableRow.expanded === true) {
            this.carrierDeliveredOrdersDetailsLayout.setSelectedTabset(this.tabSelected);
        }
        else {
            tableRow.setExpanded(true);
        }
    }

    labelCallinStatusOnDataDisplay(event: DataDisplayEvent) {
        if (event.rowData?.get("has_callins"))
            (event.target as Label).imageColor = "primary";
    }

    labelImageStatusOnDataDisplay(event: DataDisplayEvent) {
        if (event.rowData?.get("has_images"))
            (event.target as Label).imageColor = "primary";
    }

    labelDistanceOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        if (label.getDataValue() != null)
            label.caption += " MI";
    }

    displayDispatch(orderId: string, movementId: string, tableRow: TableRow) {
        const layout = Layout.getLayout("portal-carrier/CarrierDispatch", { fillHeight: true }) as CarrierDispatch;
        const dialog = CommonDialogs.createDialog(layout, { height: 750, width: 500, title: "Dispatch", okVisible: false });

        dialog.show();
        layout.addLayoutDataLoadListener(event => {
            layout.mainDataSource.parentDataSource = this.mainDataSource;
            layout.labelOrderId.caption = `Order Number ${orderId}`;
            layout.movementId = movementId;
            layout.orderId = orderId;
            layout.search({ movement_id: movementId }).then(result => {
                if (result?.modelRows?.length > 0 && layout.mainDataSource.activeRow?.get("actual_arrival_date") != null) {
                    layout.buttonSave.caption = "Dispatch";
                }
            });
            layout.buttonCancel.addClickListener((event: ClickEvent) => {
                dialog.close();
            });
            layout.buttonSave.addClickListener((event: ClickEvent) => {
                layout.buttonSaveOnClick(event, dialog, tableRow);
            });
            layout.textboxActualArrivalDate.addBlurListener((event: BlurEvent) => {
                const arrivalDateTB = event.target as Textbox
                const arrivalDate = arrivalDateTB?.getDataValue();
                if (arrivalDate != null && DateUtil.isDateValid(arrivalDate) && !DateUtil.isDateValid(layout.textboxDepartureDate.getDataValue()))
                    layout.buttonSave.caption = "Arrival Only";
                else if (!DateUtil.isDateValid(arrivalDate) && !DateUtil.isDateValid(layout.textboxDepartureDate.getDataValue()))
                    layout.buttonSave.caption = "Arrival";
            })
            layout.textboxDepartureDate.addBlurListener((event: BlurEvent) => {
                const departureDateTB = event.target as Textbox
                const departureDate = departureDateTB?.getDataValue();
                if (departureDate != null && DateUtil.isDateValid(departureDate))
                    layout.buttonSave.caption = "Dispatch";
                else if (!DateUtil.isDateValid(departureDate) && DateUtil.isDateValid(layout.textboxActualArrivalDate.getDataValue()))
                    layout.buttonSave.caption = "Arrival Only";
                else
                    layout.buttonSave.caption = "Arrival"


            });
            layout.textboxDepartureDate.dropDownAnchor = layout.horizontalspacer4;
            layout.textboxActualArrivalDate.dropDownAnchor = layout.horizontalspacer4;
        });
    }
}
