import { DataSource, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutCarrierSelectionOverview extends Layout {
    labelDelivery: Label;
    labelFreightInfo: Label;
    labelIndex1: Label;
    labelIndex2: Label;
    labelLabel2: Label;
    labelLabel4: Label;
    labelLabel5: Label;
    labelLabel6: Label;
    labelPickup: Label;
    labelPickupAddress: Label;
    labelPickupCityState: Label;
    labelPickupDelivery: Label;
    labelPickupName: Label;
    labelQuoteNumber: Label;
    labelShipDate: Label;
    labelWelcomeMsg: Label;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panel4: Panel;
    panel5: Panel;
    panel6: Panel;
    panel7: Panel;
    panelConsignee: Panel;
    panelLeft: Panel;
    panelRight: Panel;
    panelShipper: Panel;
    panelStops: Panel;
    sourceLtlQuoteOrder: DataSource;
}
