import { ChangeEvent, Checkbox, Panel, TableRow, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { DataSourceMode, ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { DisplayType, ModelRow } from "@mcleod/core";
import { AutogenLayoutHandlingRequirements } from "./autogen/AutogenLayoutHandlingRequirements";

const inputProps = { paddingTop: 0, rowBreak: false, captionVisibleInsideTable: true, width: 125, required: true };

export class HandlingRequirements extends AutogenLayoutHandlingRequirements {
    private hdrTableMap = new Map<number, TableRow>();
    hdrCallback: (row: ModelRow<any>, type: ModelDataChangeType) => void;

    async populateTable(enabled?: boolean): Promise<void> {
        if (enabled != null)
            this.enabled = enabled;
        this.sourceLtlQuoteHdrXFgp.mode = DataSourceMode.UPDATE;
        await this.sourceHandlingRequirements.search().then(() => {
            this.sourceHandlingRequirements.data.forEach(hdrDef => {
                this.addHdrRow(hdrDef)
            });
        })
        return Promise.resolve();
    }

    addHdrRow(hdrDef: ModelRow<any>) {
        const modelRow = new ModelRow(this.sourceLtlQuoteHdrXFgp.url, true, { hdr_uid: hdrDef.get("hdr_uid") });
        const result = this.tableHdrs.addRow(modelRow, { mode: TableRowMode.ADD }, { display: true, addToData: true });
        if (result.row != null) {
            const row = result.row;
            const check = row.findComponentById("checkHdr") as Checkbox;
            check.caption = hdrDef.get("hdr_title");
            check.visible = true;
            check.enabled = this.enabled;
            this.hdrTableMap.set(hdrDef.get("hdr_uid"), row);
            row["hdrDef"] = hdrDef;
        }
    }

    async populateHdrs(hdrRows: ModelRow<any>[], clearRows: boolean = false) {
        if (clearRows) {
            this.tableHdrs.clearRows();
            await this.populateTable();
        }
        this.labelNoneSelected.visible = false;
        this.tableHdrs.visible = true;
        if (hdrRows?.length == 0) {
            this.labelNoneSelected.visible = !this.enabled;
            this.tableHdrs.visible = !this.labelNoneSelected.visible;
        } else {
            hdrRows.forEach(hdr => {
                const tableRow = this.hdrTableMap.get(hdr.get("hdr_uid"));
                if (tableRow != null) {
                    hdr.set("selected", true);
                    this.sourceLtlQuoteHdrXFgp.replaceRow(tableRow.index, hdr);

                    if (!this.enabled) {
                        const panelInput = tableRow.findComponentById("panelInput") as Panel;
                        this.addInputTextboxes(tableRow["hdrDef"], panelInput, false);
                        panelInput.displayData(hdr, null, 0);
                    }
                }
            });
        }
    }

    /** This is an event handler for the onChange event of checkHdr.  */
    checkHdrOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            const check = event.target as Checkbox;
            const row = TableRow.getContainingTableRow(check);
            const panel = row.findComponentById("panelInput") as Panel;
            const hdrDef = row["hdrDef"];
            panel.visible = false;

            if (this.hdrCallback)
                this.hdrCallback(row.data, event.newValue ? ModelDataChangeType.ADD : ModelDataChangeType.DELETE);

            panel.removeAll();
            if (event.newValue)
                this.addInputTextboxes(hdrDef, panel);
        }
    }

    private addInputTextboxes(hdrDef: ModelRow<any>, panel: Panel, focusInput: boolean = true) {
        if (!hdrDef.isNull("text_title"))
            panel.add(new Textbox({ ...inputProps, enabled: this.enabled, width: undefined, field: "text_value", caption: hdrDef.get("text_title") }));

        if (!hdrDef.isNull("integer_title"))
            panel.add(new Textbox({ ...inputProps, enabled: this.enabled, field: "integer_value", displayType: DisplayType.INTEGER, caption: hdrDef.get("integer_title") }));

        if (!hdrDef.isNull("float_title"))
            panel.add(new Textbox({ ...inputProps, enabled: this.enabled, field: "float_value", displayType: DisplayType.DECIMAL, caption: hdrDef.get("float_title") }));

        if (panel.components.length > 0) {
            panel.visible = true;
            if (focusInput) {
                panel.components[0].focus();
                panel.components[0].scrollIntoView();
            }
        }
    }

    tableHdrsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const check = tableRow.findComponentById("checkHdr") as Checkbox;
        check.caption = tableRow["hdrDef"].get("hdr_title");
        check.visible = true;
        check.enabled = this.enabled;
    }
}

