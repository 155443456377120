import { Attachment, Button, DataSource, Label, Layout, Panel, SaveButton, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutFactoringImages extends Layout {
    attachmentAttachment: Attachment;
    buttonCancel: Button;
    buttonSave: SaveButton;
    buttonSaveAndClose: SaveButton;
    factoringImagesPanel: Panel;
    labelUploadImage: Label;
    panel1: Panel;
    sourceFactoringImages: DataSource;
    textboxImageTypes: Textbox;
}
