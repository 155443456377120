import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Step, Stepper, Table, Textbox } from "@mcleod/components";
import { FtlQuoteDetails } from "../FtlQuoteDetails";

export abstract class AutogenLayoutFtlQuote extends Layout {
    buttonAddStop: Button;
    horizontalspacer3: HorizontalSpacer;
    label1: Label;
    labelAddlDetails: Label;
    labelPickupDelivery: Label;
    labelWelcomeMsg: Label;
    layoutQuoteDetails: FtlQuoteDetails;
    panel2: Panel;
    panelAddlDetails: Panel;
    panelStep1: Panel;
    sourceFtlQuoteOrder: DataSource;
    sourceQuoteStops: DataSource;
    stepDetails: Step;
    stepOverview: Step;
    stepper: Stepper;
    tableStops: Table;
    textboxCommodityId: Textbox;
    textboxEquipmentTypeId: Textbox;
    textboxWeight: Textbox;
}
