import { ClickEvent, DataSourceAction, DataSourceExecutionEvent, Dialog, Snackbar, TableRow, Toast } from "@mcleod/components";
import { Api, ServerError, getThemeColor } from "@mcleod/core";
import { AutogenLayoutCarrierDispatch } from "./autogen/AutogenLayoutCarrierDispatch";

export class CarrierDispatch extends AutogenLayoutCarrierDispatch {
    orderId: string;
    movementId: string;
    private arrivalSetFlag: boolean;

    /** This is an event handler for the onClick event of buttonSave.  */
    buttonSaveOnClick(event: ClickEvent, dialog: Dialog, tableRow: TableRow) {
        if (this.validateSimple(true) == true) {
            const data = this.mainDataSource.activeRow;
            const filter = [{
                movement_id: this.movementId,
                comments: data?.get("comments"),
                arrival_date: data?.get("actual_arrival_date"),
                departure_date: data?.get("departure_date"),
                arrival_set_flag: this.arrivalSetFlag,
                sched_date: data?.get("sched_date")
            }];
            this.buttonSave.busy = true;
            Api.post("portal/carrier/carrier-dispatch", filter).then((response) => {
                if (response.data[0]?.valid == true) {
                    dialog.close();
                    if (data?.get("departure_date") != null) {
                        Toast.showSuccessToast("Dispatch", `Order ${this.orderId} was dispatched successfully`);
                        tableRow.table.removeRow(tableRow.index);
                    }
                    else
                        Toast.showSuccessToast("Dispatch", `Arrival was set for order ${this.orderId}`);
                }
                if (response.data[0]?.valid == false && response.data[0]?.validation_message != null) {
                    Snackbar.showWarningSnackbar(response.data[0]?.validation_message, { persist: true, targetPanel: this.panelDispatch });
                }
            }).catch(err => {
                let errorMessage = err
                if (err instanceof ServerError)
                    errorMessage = err.toString();
                Snackbar.showWarningSnackbar(err, { persist: true, targetPanel: this.panelDispatch });
                console.error("Error dispatching", err);
            }).finally(() => this.buttonSave.busy = false);
        }
    }

    /** This is an event handler for the afterExecution event of sourceCarrierDispatch.  */
    sourceCarrierDispatchAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH && event.dataSource?.data[0]?.get("actual_arrival_date") != null) {
            this.textboxActualArrivalDate.enabled = false;
            this.arrivalSetFlag = true;
            this.textboxActualArrivalDate._inputDiv.style.backgroundColor = getThemeColor("success");
            this.textboxDepartureDate.required = true;
        }
    }
}
