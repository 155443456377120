import { Attachment, Button, DataSource, Label, Layout, Panel, SaveButton, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutCarrierImages extends Layout {
    attachmentAttachment: Attachment;
    buttonCancel: Button;
    buttonSave: SaveButton;
    buttonSaveAndClose: SaveButton;
    carrierImagesPanel: Panel;
    labelUploadImage: Label;
    panel1: Panel;
    sourceCarrierImages: DataSource;
    textboxImageTypes: Textbox;
}
