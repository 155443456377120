import { Login, McLeodMainPage } from "@mcleod/common";
import { Api } from "@mcleod/core";
import { PortalLoginFooter } from "../portal-common/src/PortalLoginFooter";

const rootElement = document.getElementById("root");
if (rootElement == null)
    throw new Error("Could not mount component because DOM element named root could not be found.");
Login.displayCallback = PortalLoginFooter.addFooterToLogin;
Api.setLocalHostServerPort(8082);
const mainPage = new McLeodMainPage({
    settingsRoot: "portal/",
    apiContext: "mcleod-api-portal",
    modules: [
        { path: "designer", context: require["context"]("@mcleod/designer/src", true) },
        { path: "common", context: require["context"]("@mcleod/common/src", true) },
        { path: "portal-carrier", context: require["context"]("@mcleod/portal-carrier/src", true) },
        { path: "portal-customer", context: require["context"]("@mcleod/portal-customer/src", true) },
        { path: "portal-factoring", context: require["context"]("@mcleod/portal-factoring/src", true) },
        { path: "portal-reports", context: require["context"]("@mcleod/portal-reports/src", true) },
        { path: "portal-driver", context: require["context"]("@mcleod/portal-driver/src", true) },
        { path: "portal-common", context: require["context"]("@mcleod/portal-common/src", true) }
    ]
});
rootElement.appendChild(mainPage._element);
