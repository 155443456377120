import { DataSource, Label, Layout, Panel, Table, } from "@mcleod/components";

export abstract class AutogenLayoutPaymentsReceivedDetails extends Layout {
    labelPaymentDetails: Label;
    layout1: Layout;
    panel1: Panel;
    panelPaymentDetails: Panel;
    sourceFactoringPaymentDetails: DataSource;
    sourcePaymentsReceivedComments: DataSource;
    tablePaymentDetails: Table;
}
