import { Button, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutPortalLoginFooter extends Layout {
    buttonTrack: Button;
    labelDriverApplication: Label;
    labelTrackWoSignin: Label;
    panelTrackTrace: Panel;
    textboxSearchType: Textbox;
    textboxTrackingNum: Textbox;
}
