import { Component, EditRowDecorator, Label, Layout, Map, MapPin, PanelProps, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { MapVendor } from "@mcleod/components/src/components/map/MapProps";
import { ModelRow, ModelSearchResult, StringUtil, getAuthSettings, getMapSettings } from "@mcleod/core";
import { DriverChoiceManualSearch } from "./DriverChoiceManualSearch";
import { DriverChoiceTable } from "./DriverChoiceTable";
import { AutogenLayoutDriverChoice } from "./autogen/AutogenLayoutDriverChoice";
import { AutogenLayoutDriverChoicePreferences } from "./autogen/AutogenLayoutDriverChoicePreferences";

export class DriverChoice extends AutogenLayoutDriverChoice {

    private map1: Map;
    public beforeSearch = true;
    layoutTable: DriverChoiceTable;
    override async onLoad(): Promise<void> {

        this.layoutTable = Layout.getLayout("portal-driver/DriverChoiceTable", { id: "layoutTable" }) as DriverChoiceTable;
        return new Promise((resolve) => {
            this.layoutTable.addLayoutLoadListener(() => {
                this.replaceMainDatasource(this.layoutTable.sourceDriverChoice);
                this.panelTable.add(this.layoutTable);
                this.switchSwitch1.checked = true;
                this.switchSwitch1.checked = false;
                if (getMapSettings()?.distance_calc_vendor == "N")
                    this.switchSwitch1.visible = false;
                this.buttonSearchPreferences.visible = getAuthSettings().driver_settings?.allow_driver_modification;
                this.remove(this.labelNoMatchingLoadsWereFound);
                this.remove(this.layoutTable);
                this.remove(this.map1);
                this.setPanel(this.switchSwitch1.checked);
                const driverChoiceTableLayout = this.layoutTable as DriverChoiceTable;
                driverChoiceTableLayout.remove(driverChoiceTableLayout.labelDetailForOrder);
                this.configureActionsColum();
                this.switchSwitch1.addChangeListener((event) => {
                    this.setPanel(event.newValue);
                });
                return resolve();
            });
        });
    }

    public createMap1() {
        if (this.map1 == null) {
            this.map1 = new Map({
                "borderRadius": 4,
                "dataSource": this.mainDataSource,
                "fillHeight": true,
                "fillRow": true,
                "id": "map1",
                "latitudeField": "origin_lat",
                "longitudeField": "origin_lng",
                "pinLayout": "portal-driver/DriverChoiceTable",
                "vendor": MapVendor.DEFAULT_DISTANCE_VENDOR
            });

            const popupProps: Partial<PanelProps> = {};
            popupProps.top = window.innerHeight * .30;
            popupProps.left = window.innerWidth * .025;
            popupProps.width = window.innerWidth * .95;
            popupProps.height = window.innerHeight * .55;
            this.map1.setPopupProps(popupProps);

            this.map1.addPinPlotListener(event => {
                this.processAddedPin(event.pin, this.mainDataSource.data);
            });
        }
    }

    public beforeQuery() {
        this.remove(this.labelNoMatchingLoadsWereFound);
        this.createMap1();
        if (this.map1.map != null) {
            this.map1.removeAllPins();
        }
    }

    public afterQuery(rows: ModelRow<any>[]) {
        this.beforeSearch = false;

        if (!this.switchSwitch1.checked) {
            const driverChoiceTableLayout = this.layoutTable as DriverChoiceTable;
            const tableDriverChoice = driverChoiceTableLayout.tableDriverChoice;
            if (tableDriverChoice.rows.length > 0) {
                this.remove(this.labelNoMatchingLoadsWereFound);
                this.layoutTable.fillHeight = true;
            }
            else {
                this.add(this.labelNoMatchingLoadsWereFound);
                this.layoutTable.fillHeight = false;
            }
        }
    }

    public driverPositionAdded = false;
    processAddedPin(pin: MapPin, rows: ModelRow<any>[]) {
        if (pin.data == null) {
            return;
        }
        const orderId = pin.data.get("order_id");
        pin.tooltip = pin.data.get("shipper_name");
        pin.image = "map-pin-white-dot-small";
        let count = 0;
        for (let x = 0; x < rows.length; x++) {
            const modelRow = rows[x];
            const value = modelRow.get("order_id");
            const latitude = modelRow.get("origin_lat");
            const longitude = Math.abs(modelRow.get("origin_lng")) * -1;
            if (latitude === pin.latitude && longitude === pin.longitude) {
                count = count + 1;
                if (this.checkAddedRow(pin, orderId))
                    pin.rows.push(modelRow);
            }
        }
        if (count > 1) {
            pin.tooltip = pin.data.get("shipper_name");
            pin.image = "map-pin-white-dot-large";
            pin.caption = "" + count;
            pin.tooltip = "" + count + " orders at this exact location. ";
            pin.captionColor = "Blue";
        }
        if (!this.driverPositionAdded) {
            this.addLocationPin(pin.data);
            this.driverPositionAdded = true;
        }
    }

    checkAddedRow(pin: MapPin, orderId: string) {
        const length = pin.rows.length;
        for (let x = 0; x < length; x++) {
            if (pin.rows[x].get("order_id") === orderId)
                return false;
        }
        return true;
    }

    private addLocationPin(modelRow: ModelRow) {
        if (!this.driverPositionAdded && modelRow != null) {
            const lat = modelRow.get("tractor_lat");
            const lon = modelRow.get("tractor_lng");
            const pin = new MapPin(lat, lon);
            pin.layoutName = null;
            pin.image = "map-pin-location";
            pin.tooltip = "me";
            this.map1.addPin(pin);
        }
    }

    setPanel(checked: boolean) {
        if (checked) {
            this.createMap1();
            this.remove(this.layoutTable);
            this.remove(this.labelNoMatchingLoadsWereFound);
            this.add(this.map1);
            if (this.map1.map != null) {
                this.map1.fitPins();
            }
        }
        else {
            this.add(this.layoutTable);
            this.layoutTable.fillHeight = true;
            this.remove(this.map1);
            this.remove(this.labelNoMatchingLoadsWereFound);

            if (!this.beforeSearch) {
                const driverChoiceTableLayout = this.layoutTable as DriverChoiceTable;
                const tableDriverChoice = driverChoiceTableLayout.tableDriverChoice;
                if (tableDriverChoice.rows.length > 0) {
                    this.remove(this.labelNoMatchingLoadsWereFound);
                    this.layoutTable.fillHeight = true;
                }
                else {
                    this.add(this.labelNoMatchingLoadsWereFound);
                    this.layoutTable.fillHeight = false;
                }
            }
        }
    }

    reloadMainTable() {
        this.driverPositionAdded = false;
        this.beforeQuery();
        this.mainDataSource.search().then((result: ModelSearchResult) => {
            this.afterQuery(result.modelRows);
        });
    }

    tableDriverChoiceOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        tableRow.forEveryChildComponent((component: Component) => this.setNotAvailable(component, tableRow));
    }

    setNotAvailable(component: Component, tableRow: TableRow) {
        if (component instanceof Label && component.field != null) {
            if (StringUtil.isEmptyString(tableRow.data?.get(component.field))) {
                component.caption = "--";
                component.color = "default";
            }
        }
    }

    displayPreferences() {
        const layout = Layout.getLayout("portal-driver/DriverChoicePreferences") as AutogenLayoutDriverChoicePreferences;
        new EditRowDecorator({
            title: `Driver Choice Preferences`,
            layout: layout,
            layoutLoadListeners: event => {

                layout.textboxChoiceEquipType.addBeforeLookupModelSearchListener(event => {
                    event.filter.applies_to = "L";
                });

                const driverRow = new ModelRow("portal/driver/settings", false, getAuthSettings().driver_settings);
                layout.displayData(driverRow, null, 0);
                layout.mainDataSource.search({ id: getAuthSettings().driver_settings.id })

            },
            width: 1000,
            fillVerticalSpace: true,
            overlayProps: { closeOnClickOff: false, greyedBackground: true },
            onSave: (updatedData: ModelRow | any) => {
                layout.mainDataSource.post().then(result => {
                    layout.slideOut();
                    this.reloadMainTable();
                });
            },
            onClose: (cancelled: boolean) => {
                layout.slideOut()
            },
            doAfterSlideIn: () => {
            }
        });
    }

    private configureActionsColum() {
        if (this.owner instanceof DriverChoiceManualSearch) {
            const columns = this.layoutTable.tableDriverChoice.columns;
            for (let x = 0; x < columns.length; x++) {
                const column = columns[x];
                const cellId = column?.cellDef?.def?.id;
                if (cellId != null && "cellActions" == cellId)
                    column.cellDef.def.components = column.cellDef.def.components.filter(comp => comp.id === "buttonRequest");
            }
        }
    }
}
