import { ChangeEvent, Component, Label, Layout, Panel, Tab } from "@mcleod/components";
import { HorizontalAlignment, getAuthSettings, getMapSettings } from "@mcleod/core";
import { AdvancedSearch } from "./AdvancedSearch";
import { TableCustomerOrders } from "./TableCustomerOrders";
import { TableCustomerQuotes } from "./TableCustomerQuotes";
import { AutogenLayoutCustomerPortal } from "./autogen/AutogenLayoutCustomerPortal";

export enum OrderStatus {
    PROGRESS = "P",
    AVAILABLE = "A",
    DELIVERED = "D",
    ALL = "O"
}

const statuses = [OrderStatus.PROGRESS, OrderStatus.AVAILABLE, OrderStatus.DELIVERED, OrderStatus.ALL, null];

export class CustomerPortal extends AutogenLayoutCustomerPortal {

    public values = new Set();

    public override onLoad() {
        const orderTrackingPerm = getAuthSettings().permissions?.denied?.InternetModule?.["InternetModuleOrderTracking"] == null;
        if (orderTrackingPerm === false) {
            this.remove(this.tabset);
            this.add(new Label({ caption: "You do not have permission to track loads.", fillRow: true, rowBreak: false, fontBold: true, fontSize: "xlarge" }),
                Layout.getLayout("portal-customer/OrderQuoteButtonPanel", { marginRight: window.innerWidth * .07 }));
        }
        else
            this.tabset.visible = true;

        this.switchListMapView.checked = true;
        this.switchListMapView.checked = false;
        this.tabQuotes.visible = getAuthSettings().user_settings?.user_type !== "L";

        this.tabset.components.forEach((tab: Tab, index) => {
            const tabLayout = tab.components?.[0] as Layout;

            const tableCustomerOrders = tab.findComponentById((comp: Component) => { return comp instanceof TableCustomerOrders }) as TableCustomerOrders;
            if (tableCustomerOrders)
                tableCustomerOrders.orderStatus = statuses[index];

            const dataSource = tabLayout?.mainDataSource;
            if (dataSource != null) {
                dataSource.addAfterExecutionListener(() => {
                    if (tab == this.tabset.getActiveTab()) {
                        this.setTableEmptyCaption(tab);
                        let countLabel = this.tabset.getActiveTab().heading.findComponentById("labelCount") as Label;
                        if (countLabel == null) {
                            countLabel = new Label({ id: "labelCount", rowBreak: false, imageColor: "primary" });
                            this.tabset.getActiveTab().heading.add(countLabel);
                        }
                        countLabel.caption = `(${dataSource.data?.length ?? 0})`;
                    }
                })
            }
            if (tab != this.tabset.getActiveTab() && tab.heading.findComponentById("labelCount") != null)
                tab.heading.removeAt(1);
        });

        this.showSelectedTabData();

        this.switchListMapView.addChangeListener((event) => {
            const selTableLayout = this.tabset.getActiveTab()?.components?.[0] as TableCustomerOrders;
            if (selTableLayout != null) {
                selTableLayout.mapVisible = event.newValue;
            }
        });
        if (getAuthSettings().user_settings?.user_type == "L")
            this.title = "Location Portal";

        if (getMapSettings()?.distance_calc_vendor == "N")
            this.switchListMapView.visible = false;
    }


    private setTableEmptyCaption(tab: Tab) {
        const tabLayout = tab.components?.[0] as Layout;
        if (tabLayout instanceof TableCustomerOrders) {
            if ("tabAllOrders" == tab.id)
                tabLayout.tableOrders.emptyComponent = createTableEmptyComponent();
            else
                tabLayout.tableOrders.emptyComponent = createTableEmptyComponent(`No ${tab.caption.toLowerCase()} orders available for display`);
        }
        else if (tabLayout instanceof AdvancedSearch) {
            const layout = tabLayout.findComponentById("layoutTableCustomerOrders") as TableCustomerOrders;
            layout.tableOrders.emptyComponent = createTableEmptyComponent();
        } else if (tabLayout instanceof TableCustomerQuotes) {
            tabLayout.tableQuotes.emptyComponent = createTableEmptyComponent("No quotes available for display");
        }
    }

    public tabChanged(event: ChangeEvent) {
        this.showSelectedTabData();
    }

    tabsetBeforeTabSelection(event: ChangeEvent) {
        const tab = this.tabset.getActiveTab();
        if (tab.heading.findComponentById("labelCount") != null)
            tab.heading.removeAt(1);
    }

    private showSelectedTabData() {
        const selTableLayout = this.tabset.getActiveTab()?.components?.[0] as Layout;
        if (getMapSettings()?.distance_calc_vendor == "N") {
            this.switchListMapView.visible = false;
        } else {
            this.switchListMapView.visible = selTableLayout instanceof TableCustomerOrders;
        }

        const dataSource = selTableLayout.mainDataSource;
        if (!(selTableLayout instanceof AdvancedSearch)) {
            if (dataSource != null) {
                const filter: any = {};
                if (selTableLayout instanceof TableCustomerOrders) {
                    filter.status = selTableLayout.orderStatus;
                    selTableLayout.mapVisible = this.switchListMapView.checked;
                }
                dataSource.search(filter);
            }
        } else {
            if (dataSource != null) {
                const alength = dataSource.data?.length;
                if (alength > 0) {
                    //   for (let i=alength-1;i>=0; i--) {
                    //       dataSource.deleteTableRow(i);
                    //   }
                    // }
                    let countLabel = this.tabset.getActiveTab().heading.findComponentById("labelCount") as Label;
                    if (countLabel == null) {
                        countLabel = new Label({ id: "labelCount", rowBreak: false, imageColor: "primary" });
                        this.tabset.getActiveTab().heading.add(countLabel);
                    }
                    countLabel.caption = `(${dataSource.data?.length ?? 0})`;
                }
            }
        }

    }
}

export function createTableEmptyComponent(caption: string = "No orders available for display"): Panel {
    return new Panel({
        fillRow: true, align: HorizontalAlignment.CENTER, marginTop: 20,
        components: [new Label({ caption: caption, fontBold: true })]
    });
}
