import { Button, Checkbox, Label, Layout, Panel, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutARAgingReport extends Layout {
    buttonGenerateReport: Button;
    checkboxOpenCredits: Checkbox;
    checkboxPastDue: Checkbox;
    labelARAgingReport: Label;
    panelPageTitle: Panel;
    textboxAgeDate: Textbox;
    textboxInvoiceCutoff: Textbox;
    textboxPaymentCutoff: Textbox;
    textboxReportBy: Textbox;
    textboxReportType: Textbox;
}
