import { DataSource, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";
import { OrderConfirmationHeader } from "../../OrderConfirmationHeader";
import { OrderConfirmationStops } from "../../OrderConfirmationStops";
import { HandlingRequirements } from "../HandlingRequirements";
import { QuoteAndOrderOverview } from "../QuoteAndOrderOverview";

export abstract class AutogenLayoutLtlOrderConfirmation extends Layout {
    labelAddlDetails: Label;
    labelFreightInformation: Label;
    labelFreightTotals: Label;
    labelHandlingUnitsCaption: Label;
    labelTotalUnits: Label;
    labelTotalWeightDisplayValue: Label;
    labelWeightCaption: Label;
    layoutHdr: HandlingRequirements;
    layoutHeader: OrderConfirmationHeader;
    layoutOverview: QuoteAndOrderOverview;
    layoutStops: OrderConfirmationStops;
    panelAddlDetails: Panel;
    panelFgi: Panel;
    panelFrightInfo: Panel;
    panelLeft: Panel;
    panelRight: Panel;
    sourceBillingFreightGroup: DataSource;
    sourceFreightGroupItem: DataSource;
    sourceLtlOrder: DataSource;
    sourceLtlOrderHandlingReq: DataSource;
    sourceRevenueDetail: DataSource;
    tableFreightItems: Table;
    textboxBlnum: Textbox;
    textboxBolNote: Textbox;
    textboxConsigneeRefno: Textbox;
    textboxPlanningComment: Textbox;
    textboxShipperPo: Textbox;
    textboxShipperRef: Textbox;
}
