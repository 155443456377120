import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";
import { OrderTermsConditions } from "../OrderTermsConditions";

export abstract class AutogenLayoutFtlOrder extends Layout {
    buttonAddStop: Button;
    buttonCancel: Button;
    buttonSubmitOrder: Button;
    horizontalspacer2: HorizontalSpacer;
    horizontalspacer3: HorizontalSpacer;
    label1: Label;
    labelAddlDetails: Label;
    labelPickupDelivery: Label;
    labelWelcome: Label;
    layoutOrderTerms: OrderTermsConditions;
    panel1: Panel;
    panel2: Panel;
    panelAddlDetails: Panel;
    panelMain: Panel;
    sourceOrder: DataSource;
    sourceStop: DataSource;
    tableStops: Table;
    textboxBlnum: Textbox;
    textboxConsigneeRefno: Textbox;
    textboxEquipmentTypeId: Textbox;
    textboxPieces: Textbox;
    textboxPlanningComment: Textbox;
    textboxShipperPo: Textbox;
    textboxShipperRef: Textbox;
    textboxWeight: Textbox;
}
