import { Button, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutOrderConfirmationHeader extends Layout {
    buttonListView: Button;
    labelHold: Label;
    labelHoldEmail: Label;
    labelOrderConfirmation: Label;
    labelOrderSubmitMsg: Label;
    panel1: Panel;
    panelHold: Panel;
}
